import * as React from 'react';

const IconCheckWhite = (props) => (
  <svg width={41} height={41} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.132 40.26C9.035 40.26 0 31.231 0 20.13 0 9.027 9.028 0 20.132 0c11.103 0 20.131 9.027 20.131 20.13 0 11.102-9.028 20.13-20.131 20.13Zm0-38.245c-9.989 0-18.117 8.127-18.117 18.114 0 9.988 8.128 18.115 18.117 18.115 9.988 0 18.116-8.127 18.116-18.114 0-9.988-8.128-18.115-18.116-18.115Z"
      fill="white"
    />
    <path
      d="M27.78 10 32 14.22 16.44 29.8 8 21.35l4.22-4.22 4.22 4.22L27.78 10Zm0 2.8L16.44 24.16l-4.22-4.17-1.41 1.36 5.63 5.62 12.75-12.75-1.41-1.42Z"
      fill="white"
    />
  </svg>
);

export default IconCheckWhite;
