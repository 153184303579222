import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import placeholder from '../img/InvoicingImage.png';
import background from '../img/softwarePartners.png';
import RecurringInvoicing from '../img/RecurringBillingInvoicing.png';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import gsap from '@gsap/business';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function Invoicing() {
  const container = useRef();
  useGSAP(
    () => {
      // let arrowDemoHeader = gsap.to('#ArrowDemoHeader', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let arrowLearn1 = gsap.to('#ArrowLearn1', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let arrowLearn2 = gsap.to('#ArrowLearn2', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let arrowBookDemo = gsap.to('#arrowBookDemo', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let headerDemo = document.querySelector('.headerDemo');
      // let learn1 = document.querySelector('.learn1');
      // let learn2 = document.querySelector('.learn2');
      // let bookDemo = document.querySelector('.bookDemo');
      // headerDemo.addEventListener('mouseenter', () => arrowDemoHeader.play());
      // headerDemo.addEventListener('mouseleave', () => arrowDemoHeader.reverse());
      // learn1.addEventListener('mouseenter', () => arrowLearn1.play());
      // learn1.addEventListener('mouseleave', () => arrowLearn1.reverse());
      // learn2.addEventListener('mouseenter', () => arrowLearn2.play());
      // learn2.addEventListener('mouseleave', () => arrowLearn2.reverse());
      // bookDemo.addEventListener('mouseenter', () => arrowBookDemo.play());
      // bookDemo.addEventListener('mouseleave', () => arrowBookDemo.reverse());
    },
    { scope: container }
  );
  return (
    <>
      <MobileNavigation />
      <main
        ref={container}
        className="w-full h-full px-[200px] py-10 flex-col justify-start items-center gap-[100px] inline-flex"
      >
        <Header />
        <section className="inline-flex items-center self-stretch justify-between max-[1516px]:justify-center max-[1516px]:grid">
          <article className="h-[323px] inline-flex flex-col items-start justify-center gap-6 max-[1516px]:items-center">
            <h2 className="text-[#4452a3] text-[25px] font-light font-customFont">INVOICING</h2>
            <h1 className="text-black text-[45px] font-medium font-customFont">
              Get paid <span className="font-semibold text-[#C0F]">faster</span>.
            </h1>
            <p className="text-black text-[25px] font-light font-customFont">
              Simplify invoicing with tools to create, send and track payments effortlessly - all
              from one convenient platform.
            </p>
            <Link
              to="/bookDemo"
              className="flex items-center justify-center gap-4 px-4 py-2 rounded-[20px] bg-[#4452a3] headerDemo"
            >
              <p className="text-xl font-medium text-white font-customFont">Book a Demo</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[65rem] max-[1516px]:w-[634px] h-[450px] justify-self-center self-center grid">
            <img className="self-center justify-self-center" src={placeholder} alt="Placeholder" />
          </figure>
        </section>
        <section className="flex self-stretch justify-between max-[1516px]:justify-center max-[1516px]:gap-4 items-center max-[1516px]:grid cards">
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col items-start justify-self-center gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[96.88px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="97"
                height="76"
                viewBox="0 0 97 76"
                fill="none"
              >
                <path
                  d="M0.00181367 65.8509V12.6827C0.00181367 5.72861 5.63862 0.0917969 12.5927 0.0917969H84.2912C91.2453 0.0917969 96.8803 5.7286 96.8803 12.6809V53.2601C96.8803 60.2141 91.2435 65.8509 84.2894 65.8509H12.5909C5.63681 65.8509 0 72.805 0 65.8509H0.00181367Z"
                  fill="white"
                />
                <path
                  d="M86.1882 61.1443H10.6937C7.39422 61.1443 4.70898 58.4591 4.70898 55.1596V10.7845C4.70898 7.48504 7.39422 4.7998 10.6937 4.7998H67.1669C67.9967 4.7998 68.6707 5.47382 68.6707 6.30367C68.6707 7.13351 67.9967 7.80755 67.1669 7.80755H10.6937C9.0521 7.80755 7.71673 9.14293 7.71673 10.7845V55.1596C7.71673 56.8012 9.0521 58.1366 10.6937 58.1366H86.1882C87.8297 58.1366 89.1651 56.8012 89.1651 55.1596V10.7845C89.1651 9.14293 87.8297 7.80755 86.1882 7.80755H75.6937C74.8638 7.80755 74.1898 7.13351 74.1898 6.30367C74.1898 5.47382 74.8638 4.7998 75.6937 4.7998H86.1882C89.4876 4.7998 92.1747 7.48504 92.1747 10.7845V55.1596C92.1747 58.4591 89.4894 61.1443 86.1882 61.1443Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M31.9729 19.7093V29.4174H17.0429V19.7093H31.9729ZM32.6886 16.6997H16.3272C15.0607 16.6997 14.0333 17.7271 14.0333 18.9936V30.1313C14.0333 31.3978 15.0607 32.4252 16.3272 32.4252H32.6886C33.9551 32.4252 34.9825 31.3978 34.9825 30.1313V18.9936C34.9825 17.7271 33.9551 16.6997 32.6886 16.6997Z"
                  fill="#1C7ABF"
                />
                <path d="M28.3437 38.6216H14.0333V41.6312H28.3437V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5132 38.6216H32.201V41.6312H46.5132V38.6216Z" fill="#1C7ABF" />
                <path d="M82.8492 38.6216H68.5388V41.6312H82.8492V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5134 46.3169H14.0333V49.3265H46.5134V46.3169Z" fill="#1C7ABF" />
                <path d="M82.8489 16.939H58.6782V19.9485H82.8489V16.939Z" fill="#1C7ABF" />
                <path
                  d="M21.19 32.202C20.3584 32.202 19.6862 31.528 19.6862 30.6981V18.4443C19.6862 17.6126 20.3602 16.9404 21.19 16.9404C22.0199 16.9404 22.6939 17.6144 22.6939 18.4443V30.6981C22.6939 31.528 22.0199 32.202 21.19 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M27.9704 32.202C27.1388 32.202 26.4666 31.528 26.4666 30.6981V18.4443C26.4666 17.6126 27.1406 16.9404 27.9704 16.9404C28.8003 16.9404 29.4743 17.6144 29.4743 18.4443V30.6981C29.4743 31.528 28.8003 32.202 27.9704 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.4458 51.2082C52.4061 62.796 61.7678 72.2219 73.3556 72.2616C84.9434 72.3012 94.3694 62.9396 94.409 51.3518C94.4487 39.7639 85.087 30.338 73.4992 30.2983C61.9114 30.2587 52.4854 39.6203 52.4458 51.2082Z"
                  fill="white"
                />
                <path
                  d="M73.3947 75.0901C67.9391 75.0901 62.7317 73.1333 58.5934 69.5022C54.0799 65.5396 51.3784 60.0568 50.9888 54.0631C50.5993 48.0693 52.5652 42.2821 56.5278 37.7687C60.4904 33.2552 65.9732 30.5537 71.967 30.1641C77.9625 29.7728 83.7479 31.7405 88.2613 35.7049C92.7748 39.6675 95.4763 45.1503 95.8659 51.1441C96.2554 57.1378 94.2877 62.9232 90.3251 67.4385C86.3625 71.9519 80.8797 74.6534 74.8859 75.043C74.3877 75.0756 73.8894 75.0919 73.3929 75.0919L73.3947 75.0901ZM73.4382 33.1338C68.0225 33.1338 62.6339 35.3733 58.789 39.7527C55.3573 43.6628 53.6523 48.6745 53.9911 53.8674C54.3281 59.0584 56.6691 63.8074 60.5792 67.241C64.4893 70.6727 69.5118 72.3777 74.6921 72.0389C79.8831 71.7019 84.6321 69.3609 88.0657 65.4508C91.4974 61.5407 93.2024 56.529 92.8636 51.338C92.5266 46.1469 90.1874 41.3979 86.2773 37.9643C82.5847 34.7247 78.0042 33.1338 73.44 33.1338H73.4382Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M89.446 51.1662C89.8247 55.397 88.5364 59.7672 85.5142 63.2117C79.6545 69.8867 69.4934 70.548 62.8183 64.6884C56.1433 58.8287 55.482 48.6676 61.3416 41.9925C67.2013 35.3175 77.3624 34.6562 84.0375 40.5158C84.8728 41.2479 85.612 42.0487 86.257 42.9003L89.446 51.1662Z"
                  fill="white"
                />
                <path
                  d="M73.4016 70.1917C69.1346 70.1917 65.0615 68.6624 61.8254 65.8214C58.2941 62.723 56.1832 58.4343 55.877 53.7469C55.5726 49.0595 57.1109 44.5334 60.2092 41.002C66.607 33.7145 77.7393 32.9898 85.0286 39.3876C85.9237 40.1722 86.7408 41.0491 87.4547 41.9931C87.9566 42.6563 87.828 43.5985 87.1648 44.1004C86.5017 44.6004 85.5595 44.4736 85.0576 43.8104C84.4633 43.0277 83.7874 42.2993 83.0427 41.6489C77.0019 36.3472 67.7739 36.9452 62.4705 42.9878C59.9012 45.9141 58.6256 49.6665 58.8793 53.5512C59.133 57.4377 60.8832 60.9926 63.8095 63.5601C69.8503 68.8635 79.0801 68.262 84.3817 62.2211C87.0398 59.1934 88.3045 55.316 87.9457 51.3008C87.8715 50.4746 88.4821 49.7426 89.3101 49.6683C90.1418 49.5958 90.8683 50.2046 90.9426 51.0326C91.3757 55.874 89.8482 60.5524 86.643 64.2051C83.5446 67.7347 79.2559 69.8474 74.5685 70.1536C74.1789 70.179 73.7876 70.1917 73.4016 70.1917Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M74.6101 62.1789H70.758C68.5475 62.1789 66.7483 60.6008 66.7483 58.6621V56.2903H69.7578V58.6621C69.7578 58.836 70.1474 59.1712 70.758 59.1712H74.6101C75.2207 59.1712 75.6103 58.8378 75.6103 58.6621V56.9027C75.6103 55.8772 74.7623 54.935 73.4487 54.5001L71.5462 53.8714C68.9787 53.0252 67.319 50.9597 67.319 48.6115V46.5423C67.319 44.6017 69.1164 43.0254 71.327 43.0254H74.6119C76.8224 43.0254 78.6198 44.6036 78.6198 46.5423V48.7582H75.6103V46.5423C75.6103 46.3683 75.2207 46.0331 74.6101 46.0331H71.3251C70.7145 46.0331 70.325 46.3665 70.325 46.5423V48.6115C70.325 49.637 71.1729 50.5792 72.4866 51.014L74.3891 51.6428C76.9565 52.4889 78.6162 54.5545 78.6162 56.9027V58.6621C78.6162 60.6008 76.8188 62.1789 74.6083 62.1789H74.6101Z"
                  fill="#1C7ABF"
                />
                <path d="M74.5757 40.6294H71.5662V44.5304H74.5757V40.6294Z" fill="#1C7ABF" />
                <path d="M74.5757 60.6743H71.5662V64.5753H74.5757V60.6743Z" fill="#1C7ABF" />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Accept Card and ACH payments
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[72.866px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="74"
                height="75"
                viewBox="0 0 74 75"
                fill="none"
              >
                <path
                  d="M67.9082 67.6704C67.2722 67.6704 66.756 67.1542 66.756 66.5182V21.6552C66.756 19.1832 64.7449 17.1737 62.2744 17.1737H23.7105C23.0744 17.1737 22.5582 16.6575 22.5582 16.0214C22.5582 15.3854 23.0744 14.8691 23.7105 14.8691H62.2744C66.017 14.8691 69.0605 17.9142 69.0605 21.6552V66.5182C69.0605 67.1542 68.5443 67.6704 67.9082 67.6704Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M67.73 74.9973H32.5291C29.5133 74.9973 27.0597 72.5438 27.0597 69.5279V20.523C27.0597 18.6748 25.5572 17.1722 23.7089 17.1722C21.8607 17.1722 20.3582 18.6748 20.3582 20.523V41.3974C20.3582 44.4931 17.8386 47.0127 14.7428 47.0127C13.2848 47.0127 11.9236 46.452 10.9065 45.4334C9.848 44.3717 9.26726 42.9322 9.27187 41.382V12.0316H1.48564C0.849588 12.0316 0.333374 11.5154 0.333374 10.8793V5.65726C0.333374 2.53847 2.8699 0.00195312 5.98869 0.00195312C9.10748 0.00195312 11.5764 2.50774 11.5764 5.58966V41.3866C11.5733 42.3238 11.9159 43.1826 12.5397 43.8079C13.1204 44.3902 13.9024 44.7113 14.7428 44.7113C16.568 44.7113 18.0536 43.2256 18.0536 41.4004V20.5261C18.0536 17.4073 20.5902 14.8707 23.7089 14.8707C26.8277 14.8707 29.3643 17.4073 29.3643 20.5261V69.531C29.3643 71.2763 30.7838 72.6959 32.5291 72.6959C34.2744 72.6959 35.694 71.2763 35.694 69.531V66.5213C35.694 65.8852 36.2102 65.369 36.8463 65.369H72.0471C72.6832 65.369 73.1994 65.8852 73.1994 66.5213V69.531C73.1994 72.5469 70.7458 75.0004 67.73 75.0004V74.9973ZM36.9876 72.6928H67.73C69.4753 72.6928 70.8949 71.2732 70.8949 69.5279V67.6705H37.9985V69.5279C37.9985 70.7063 37.6237 71.7987 36.9876 72.6928ZM2.63944 9.72705H9.2734V5.58812C9.2734 3.7783 7.80158 2.30494 5.99022 2.30494C4.17887 2.30494 2.63944 3.80749 2.63944 5.65572V9.72551V9.72705Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M28.2135 47.0125H14.7443C14.1083 47.0125 13.592 46.4963 13.592 45.8603C13.592 45.2242 14.1083 44.708 14.7443 44.708H28.2135C28.8495 44.708 29.3658 45.2242 29.3658 45.8603C29.3658 46.4963 28.8495 47.0125 28.2135 47.0125Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M49.9637 17.1733C49.3276 17.1733 48.8114 16.6571 48.8114 16.0211V5.25893C48.8114 3.6304 47.4871 2.30452 45.857 2.30452H5.99028C5.35423 2.30452 4.83801 1.78831 4.83801 1.15226C4.83801 0.516214 5.35423 0 5.99028 0H45.857C48.7561 0 51.1159 2.3583 51.1159 5.25893V16.0195C51.1159 16.6556 50.5997 17.1718 49.9637 17.1718V17.1733Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M34.9197 60.2511C34.2837 60.2511 33.7675 59.7349 33.7675 59.0989V56.8681C33.7675 56.232 34.2837 55.7158 34.9197 55.7158C35.5558 55.7158 36.072 56.232 36.072 56.8681V59.0989C36.072 59.7349 35.5558 60.2511 34.9197 60.2511Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M39.3813 60.2514C38.7452 60.2514 38.229 59.7352 38.229 59.0992V54.6376C38.229 54.0016 38.7452 53.4854 39.3813 53.4854C40.0173 53.4854 40.5335 54.0016 40.5335 54.6376V59.0992C40.5335 59.7352 40.0173 60.2514 39.3813 60.2514Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M43.8428 60.2515C43.2068 60.2515 42.6906 59.7353 42.6906 59.0992V53.1044C42.6906 52.4684 43.2068 51.9521 43.8428 51.9521C44.4789 51.9521 44.9951 52.4684 44.9951 53.1044V59.0992C44.9951 59.7353 44.4789 60.2515 43.8428 60.2515Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M48.3044 60.2511C47.6683 60.2511 47.1521 59.7349 47.1521 59.0988V50.1757C47.1521 49.5397 47.6683 49.0234 48.3044 49.0234C48.9404 49.0234 49.4566 49.5397 49.4566 50.1757V59.0988C49.4566 59.7349 48.9404 60.2511 48.3044 60.2511Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.7659 60.2515C52.1299 60.2515 51.6136 59.7353 51.6136 59.0992V53.1044C51.6136 52.4684 52.1299 51.9521 52.7659 51.9521C53.402 51.9521 53.9182 52.4684 53.9182 53.1044V59.0992C53.9182 59.7353 53.402 60.2515 52.7659 60.2515Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M57.229 60.2515C56.593 60.2515 56.0768 59.7353 56.0768 59.0992V48.3632C56.0768 47.7272 56.593 47.2109 57.229 47.2109C57.8651 47.2109 58.3813 47.7272 58.3813 48.3632V59.0992C58.3813 59.7353 57.8651 60.2515 57.229 60.2515Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.6906 60.2514C61.0545 60.2514 60.5383 59.7352 60.5383 59.0992V43.622C60.5383 42.9859 61.0545 42.4697 61.6906 42.4697C62.3266 42.4697 62.8429 42.9859 62.8429 43.622V59.0992C62.8429 59.7352 62.3266 60.2514 61.6906 60.2514Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M48.3044 37.6639H34.9197C34.2837 37.6639 33.7675 37.1477 33.7675 36.5116C33.7675 35.8756 34.2837 35.3594 34.9197 35.3594H48.3044C48.9404 35.3594 49.4567 35.8756 49.4567 36.5116C49.4567 37.1477 48.9404 37.6639 48.3044 37.6639Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.6906 31.3612H34.9197C34.2837 31.3612 33.7675 30.845 33.7675 30.2089C33.7675 29.5729 34.2837 29.0566 34.9197 29.0566H61.6906C62.3267 29.0566 62.8429 29.5729 62.8429 30.2089C62.8429 30.845 62.3267 31.3612 61.6906 31.3612Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M53.0026 25.0594H34.9197C34.2837 25.0594 33.7675 24.5432 33.7675 23.9071C33.7675 23.2711 34.2837 22.7549 34.9197 22.7549H53.0026C53.6386 22.7549 54.1548 23.2711 54.1548 23.9071C54.1548 24.5432 53.6386 25.0594 53.0026 25.0594Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.6906 25.0594H57.8435C57.2075 25.0594 56.6913 24.5432 56.6913 23.9071C56.6913 23.2711 57.2075 22.7549 57.8435 22.7549H61.6906C62.3266 22.7549 62.8428 23.2711 62.8428 23.9071C62.8428 24.5432 62.3266 25.0594 61.6906 25.0594Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M43.2882 10.1229H25.3882C24.7522 10.1229 24.236 9.60667 24.236 8.97062C24.236 8.33457 24.7522 7.81836 25.3882 7.81836H43.2882C43.9243 7.81836 44.4405 8.33457 44.4405 8.97062C44.4405 9.60667 43.9243 10.1229 43.2882 10.1229Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.1369 10.1229H16.5173C15.8812 10.1229 15.365 9.60667 15.365 8.97062C15.365 8.33457 15.8812 7.81836 16.5173 7.81836H20.1369C20.7729 7.81836 21.2891 8.33457 21.2891 8.97062C21.2891 9.60667 20.7729 10.1229 20.1369 10.1229Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Automatic payment receipts
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[80.952px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="82"
                height="76"
                viewBox="0 0 82 76"
                fill="none"
              >
                <g clipPath="url(#clip0_701_4159)">
                  <path
                    d="M41.0095 28.1976C36.3527 28.1976 32.1001 25.2673 30.4346 20.9041C29.9334 19.5927 29.6747 18.2246 29.6747 16.8404C29.6747 10.583 34.76 5.49121 41.0095 5.49121C47.259 5.49121 52.3443 10.583 52.3443 16.8404C52.3443 18.2246 52.0855 19.5846 51.5843 20.9041C49.9108 25.2673 45.6663 28.1976 41.0095 28.1976ZM41.0095 7.87113C36.0697 7.87113 32.0516 11.8943 32.0516 16.8404C32.0516 17.9251 32.2537 19.0098 32.6499 20.046C33.9677 23.4944 37.3309 25.8096 41.0095 25.8096C44.688 25.8096 48.0513 23.4944 49.3691 20.046C49.7652 19.0017 49.9673 17.9251 49.9673 16.8404C49.9673 11.8943 45.9492 7.87113 41.0095 7.87113Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M80.4306 75.0917H1.85514C1.20028 75.0917 0.666687 74.5574 0.666687 73.9018V27.1532C0.666687 26.789 0.828382 26.449 1.11135 26.2223L19.4233 11.5299C19.9326 11.1171 20.6845 11.198 21.0887 11.7161C21.501 12.2261 21.4121 12.9789 20.9028 13.3837L3.0436 27.7199V72.7118H79.2422V27.728L61.0354 13.1246C60.526 12.7118 60.4371 11.9671 60.8494 11.4571C61.2617 10.9471 62.0055 10.858 62.5149 11.2709L81.1663 26.2304C81.4493 26.4571 81.611 26.7971 81.611 27.1613V73.9099C81.611 74.5655 81.0774 75.0998 80.4225 75.0998L80.4306 75.0917Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M80.4306 75.0919C80.2204 75.0919 80.0183 75.0352 79.8242 74.9219L1.24877 28.1734C0.682841 27.8415 0.496892 27.1129 0.836451 26.5463C1.16792 25.9796 1.90364 25.7934 2.46148 26.1334L81.0369 72.8819C81.6029 73.2138 81.7888 73.9424 81.4493 74.509C81.231 74.8814 80.8348 75.0919 80.4306 75.0919Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M1.85513 75.0919C1.45089 75.0919 1.05474 74.8814 0.836451 74.509C0.496892 73.9424 0.682841 73.2138 1.24877 72.8819L79.8242 26.1334C80.3902 25.7934 81.1178 25.9796 81.4493 26.5463C81.7807 27.1129 81.6029 27.8415 81.0369 28.1734L2.46148 74.9219C2.27553 75.0352 2.06533 75.0919 1.85513 75.0919Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M61.7791 39.3363C61.1243 39.3363 60.5907 38.802 60.5907 38.1463V2.47172H21.3555V38.0492C21.3555 38.7049 20.8219 39.2391 20.167 39.2391C19.5122 39.2391 18.9786 38.7049 18.9786 38.0492V1.28176C18.9786 0.626066 19.5122 0.0917969 20.167 0.0917969H61.7791C62.434 0.0917969 62.9676 0.626066 62.9676 1.28176V38.1463C62.9676 38.802 62.434 39.3363 61.7791 39.3363Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M54.9718 32.6739H27.7586C27.1037 32.6739 26.5701 32.1396 26.5701 31.4839C26.5701 30.8282 27.1037 30.2939 27.7586 30.2939H54.9718C55.6266 30.2939 56.1602 30.8282 56.1602 31.4839C56.1602 32.1396 55.6266 32.6739 54.9718 32.6739Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M54.7455 38.1138H27.5323C26.8774 38.1138 26.3438 37.5795 26.3438 36.9238C26.3438 36.2682 26.8774 35.7339 27.5323 35.7339H54.7455C55.4003 35.7339 55.9339 36.2682 55.9339 36.9238C55.9339 37.5795 55.4003 38.1138 54.7455 38.1138Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.7047 22.3207H40.6052C39.0934 22.3207 37.8645 21.0902 37.8645 19.5765C37.8645 18.9208 38.3981 18.3865 39.053 18.3865C39.7078 18.3865 40.2414 18.9208 40.2414 19.5765C40.2414 19.7789 40.4031 19.9408 40.6052 19.9408H41.7047C41.9069 19.9408 42.0686 19.7789 42.0686 19.5765V18.4756C42.0686 18.2732 41.9069 18.1113 41.7047 18.1113H40.6052C39.0934 18.1113 37.8645 16.8809 37.8645 15.3671V14.2662C37.8645 12.7524 39.0934 11.522 40.6052 11.522H41.7047C43.2166 11.522 44.4455 12.7524 44.4455 14.2662C44.4455 14.9219 43.9119 15.4804 43.257 15.4804C42.6022 15.4804 42.0686 14.9704 42.0686 14.3147C42.0686 14.0638 41.9069 13.9019 41.7047 13.9019H40.6052C40.4031 13.9019 40.2414 14.0638 40.2414 14.2662V15.3671C40.2414 15.5695 40.4031 15.7314 40.6052 15.7314H41.7047C43.2166 15.7314 44.4455 16.9618 44.4455 18.4675V19.5684C44.4455 21.0821 43.2166 22.3126 41.7047 22.3126V22.3207Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.155 13.7479C40.5001 13.7479 39.9666 13.2136 39.9666 12.5579V10.8985C39.9666 10.2428 40.5001 9.7085 41.155 9.7085C41.8099 9.7085 42.3435 10.2428 42.3435 10.8985V12.5579C42.3435 13.2136 41.8099 13.7479 41.155 13.7479Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.155 23.9803C40.5001 23.9803 39.9666 23.446 39.9666 22.7904V21.1309C39.9666 20.4752 40.5001 19.9409 41.155 19.9409C41.8099 19.9409 42.3435 20.4752 42.3435 21.1309V22.7904C42.3435 23.446 41.8099 23.9803 41.155 23.9803Z"
                    fill="#1C7ABF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_701_4159">
                    <rect
                      width="80.9524"
                      height="75"
                      fill="white"
                      transform="translate(0.666687 0.0917969)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">Send via email</p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[73.927px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="74"
                height="75"
                viewBox="0 0 74 75"
                fill="none"
              >
                <path
                  d="M65.8166 75.0002H8.11048C7.50072 75.0002 7.00851 74.508 7.00851 73.8983V66.3168C7.00851 65.7071 7.50072 65.2148 8.11048 65.2148C8.72023 65.2148 9.21244 65.7071 9.21244 66.3168V72.7963H64.7147V66.3168C64.7147 65.7071 65.2069 65.2148 65.8166 65.2148C66.4264 65.2148 66.9186 65.7071 66.9186 66.3168V73.8983C66.9186 74.508 66.4264 75.0002 65.8166 75.0002Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M36.9452 74.7504C36.3354 74.7504 35.8432 74.2582 35.8432 73.6484V66.1918C35.8432 65.5821 36.3354 65.0898 36.9452 65.0898C37.5549 65.0898 38.0472 65.5821 38.0472 66.1918V73.6484C38.0472 74.2582 37.5549 74.7504 36.9452 74.7504Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M8.11048 53.2764C7.50072 53.2764 7.00851 52.7842 7.00851 52.1744V39.6781C7.00851 39.0684 7.50072 38.5762 8.11048 38.5762C8.72023 38.5762 9.21244 39.0684 9.21244 39.6781V52.1744C9.21244 52.7842 8.72023 53.2764 8.11048 53.2764Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M36.9452 53.2765C36.3354 53.2765 35.8432 52.7842 35.8432 52.1745V39.3256C35.8432 38.7158 36.3354 38.2236 36.9452 38.2236C37.5549 38.2236 38.0472 38.7158 38.0472 39.3256V52.1745C38.0472 52.7842 37.5549 53.2765 36.9452 53.2765Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M65.8169 53.2764C65.2071 53.2764 64.7149 52.7842 64.7149 52.1744V39.6781C64.7149 39.0684 65.2071 38.5762 65.8169 38.5762C66.4266 38.5762 66.9188 39.0684 66.9188 39.6781V52.1744C66.9188 52.7842 66.4266 53.2764 65.8169 53.2764Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M37.0187 26.0515C36.409 26.0515 35.9168 25.5593 35.9168 24.9495V15.1274C35.9168 14.5176 36.409 14.0254 37.0187 14.0254C37.6285 14.0254 38.1207 14.5176 38.1207 15.1274V24.9495C38.1207 25.5593 37.6285 26.0515 37.0187 26.0515Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M15.1192 26.4036C14.8694 26.4036 14.6123 26.3155 14.4066 26.1392C13.9437 25.7424 13.885 25.0519 14.2817 24.5817L22.9652 14.4069C23.3619 13.9441 24.0524 13.8927 24.5153 14.282C24.9781 14.6787 25.0369 15.3693 24.6401 15.8395L15.9567 26.0143C15.7363 26.2714 15.4277 26.4036 15.1192 26.4036Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M58.7717 26.4027C58.4632 26.4027 58.1472 26.2706 57.9342 26.0134L49.2507 15.8386C48.854 15.3757 48.9128 14.6778 49.3756 14.2811C49.8384 13.8844 50.5364 13.9432 50.9257 14.406L59.6092 24.5808C60.0059 25.0436 59.9471 25.7416 59.4843 26.1383C59.2786 26.3146 59.0215 26.4027 58.7717 26.4027Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M15.1559 67.2931H1.13892C0.529167 67.2931 0.0369568 66.8009 0.0369568 66.1912V52.1742C0.0369568 51.5645 0.529167 51.0723 1.13892 51.0723H15.1559C15.7657 51.0723 16.2579 51.5645 16.2579 52.1742V66.1912C16.2579 66.8009 15.7657 67.2931 15.1559 67.2931ZM2.24088 65.0892H14.054V53.2762H2.24088V65.0892Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M43.9906 67.2931H29.9736C29.3639 67.2931 28.8717 66.8009 28.8717 66.1912V52.1742C28.8717 51.5645 29.3639 51.0723 29.9736 51.0723H43.9906C44.6004 51.0723 45.0926 51.5645 45.0926 52.1742V66.1912C45.0926 66.8009 44.6004 67.2931 43.9906 67.2931ZM31.0756 65.0892H42.8887V53.2762H31.0756V65.0892Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M72.8253 67.2931H58.8084C58.1986 67.2931 57.7064 66.8009 57.7064 66.1912V52.1742C57.7064 51.5645 58.1986 51.0723 58.8084 51.0723H72.8253C73.4351 51.0723 73.9273 51.5645 73.9273 52.1742V66.1912C73.9273 66.8009 73.4351 67.2931 72.8253 67.2931ZM59.9103 65.0892H71.7234V53.2762H59.9103V65.0892Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M15.1189 40.428H1.10196C0.492211 40.428 0 39.9358 0 39.326V25.309C0 24.6992 0.492211 24.207 1.10196 24.207H15.1189C15.7287 24.207 16.2209 24.6992 16.2209 25.309V39.326C16.2209 39.9358 15.7287 40.428 15.1189 40.428ZM2.20393 38.2241H14.017V26.411H2.20393V38.2241Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M43.9537 40.428H29.9367C29.3269 40.428 28.8347 39.9358 28.8347 39.326V25.309C28.8347 24.6992 29.3269 24.207 29.9367 24.207H43.9537C44.5634 24.207 45.0556 24.6992 45.0556 25.309V39.326C45.0556 39.9358 44.5634 40.428 43.9537 40.428ZM31.0386 38.2241H42.8517V26.411H31.0386V38.2241Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M72.7884 40.428H58.7714C58.1616 40.428 57.6694 39.9358 57.6694 39.326V25.309C57.6694 24.6992 58.1616 24.207 58.7714 24.207H72.7884C73.3981 24.207 73.8903 24.6992 73.8903 25.309V39.326C73.8903 39.9358 73.3981 40.428 72.7884 40.428ZM59.8734 38.2241H71.6864V26.411H59.8734V38.2241Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M50.088 16.2282H23.7952C23.1854 16.2282 22.6932 15.736 22.6932 15.1263V1.10196C22.6932 0.492211 23.1854 0 23.7952 0H50.088C50.6978 0 51.19 0.492211 51.19 1.10196V15.1263C51.19 15.736 50.6978 16.2282 50.088 16.2282ZM24.8971 14.0243H48.986V2.20393H24.8971V14.0243Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Fully integrated with other iQ Pro+ features
            </p>
          </article>
        </section>
        <section className="flex flex-col items-start gap-[60px] self-stretch">
          <h1 className="text-center self-center text-black text-[35px] font-semibold font-customFont">
            Easily create, send and manage invoices -{' '}
            <span className="text-[#C0F]">all in one place</span>.
          </h1>
        </section>
        <section className="flex w-full max-md:w-auto px-[122px] py-[100px] flex-col items-start gap-[50px] bg-[url('./img/softwarePartners.png')] bg-cover bg-bottom md:rounded-3xl">
          <h1 className="flex flex-col items-start justify-center text-white text-[55px] font-medium font-customFont">
            How do Invoices work?
          </h1>
          <div className="flex flex-col justify-center items-start gap-[50px] self-stretch">
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Create customized Invoices in a few simple steps: choose or add a Customer, enter
                  Invoice details and payment terms, then add line items.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Give customers the flexibility to pay by Card or ACH and customize Invoices to
                  allow partial payments or require full payment.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Email Invoices directly to customers with a secure payment link, reducing your PCI
                  compliance burden and safeguarding customer data.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Offer a unique Payables URL for your customers, making it easy to manage and pay
                  multiple Invoices in one place.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Track payment statuses in real-time on the Invoices dashboard. View, edit, clone
                  or cancel invoices and manage associated Customers or Transactions.
                </p>
              </div>
            </article>
          </div>
        </section>
        <section
          className="flex p-[60px] flex-col gap-[50px] self-stretch rounded-[10px] border-[2px] border-solid border-[#4452A3]"
          style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
        >
          <p className="text-center text-black text-[35px] font-normal font-customFont">
            Stay <span className="font-semibold text-[#C0F]">PCI compliant</span> by elimating the
            need to store sentitive payment information in your system.
          </p>
        </section>
        <div className="w-[1054px] max-[1054px]:w-[300px] h-[0px] border border-black"></div>
        <section className="flex-col justify-center items-start gap-[75px] inline-flex">
          <h1 className="text-[#23242a] text-[45px] font-medium font-customFont">
            Customer Payables
          </h1>
          <p className="text-[#23242a] text-[25px] font-normal font-customFont">
            Offer customers an effortless way to manage and pay multiple invoices by generating a{' '}
            <span className="font-medium text-[#C0F]">unique Customer Payables URL</span>, providing
            customers a consolidated view of all their outstanding invoices.
          </p>
        </section>
        <section
          className="w-full px-[122px] py-[100px] flex-col justify-start items-start gap-[50px] inline-flex"
          style={{
            background: `url(${background}) -185.199px -1334.503px / 126.488% 239.295% no-repeat`
          }}
        >
          <div className="inline-flex flex-col items-start justify-center gap-6">
            <h2 className="text-white text-[45px] font-medium font-customFont">
              How does Customer Payables work?
            </h2>
          </div>
          <article className="flex-col justify-center items-start gap-[50px] inline-flex">
            <div className="justify-start items-start gap-[45px] inline-flex">
              <div class="w-[30px] h-[30px] relative">
                <div class="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-white text-[25px] font-bold font-customFont">
                  Consolidated View of Outstanding Invoices
                </h3>
                <br />
                <br />
                <p className="text-white text-[25px] font-light font-customFont">
                  {' '}
                  This unique URL provides a Customer with a dedicated page showing all their
                  outstanding Invoices. This organized view makes it easy to see their total balance
                  and select invoices to pay.
                </p>
              </div>
            </div>
          </article>
          <article className="flex-col justify-center items-start gap-[50px] inline-flex">
            <div className="justify-start items-start gap-[45px] inline-flex">
              <div class="w-[30px] h-[30px] relative">
                <div class="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-white text-[25px] font-bold font-customFont">
                  Flexible Payment Options
                </h3>
                <br />
                <br />
                <p className="text-white text-[25px] font-light font-customFont">
                  {' '}
                  Customers can select up to 10 Invoices per Transaction with options filtered by
                  the chosen payment method - Card or ACH - to show only eligible Invoives.
                </p>
              </div>
            </div>
          </article>
          <article className="flex-col justify-center items-start gap-[50px] inline-flex">
            <div className="justify-start items-start gap-[45px] inline-flex">
              <div class="w-[30px] h-[30px] relative">
                <div class="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-white text-[25px] font-bold font-customFont">
                  Simple Invoice Selection
                </h3>
                <br />
                <br />
                <p className="text-white text-[25px] font-light font-customFont">
                  {' '}
                  Customers can manage their balances with flexibility and ease by selecting
                  Invoices and enabling full or partial payments.
                </p>
              </div>
            </div>
          </article>
          <article className="flex-col justify-center items-start gap-[50px] inline-flex">
            <div className="justify-start items-start gap-[45px] inline-flex">
              <div class="w-[30px] h-[30px] relative">
                <div class="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-white text-[25px] font-bold font-customFont">
                  Seamless Payment Submission
                </h3>
                <br />
                <br />
                <p className="text-white text-[25px] font-light font-customFont">
                  {' '}
                  After selecting Invoices and entering payment details, Customers can submit
                  payments. Each Invoice is processed separately to ensure clear records of each
                  payment.
                </p>
              </div>
            </div>
          </article>
          <article className="flex-col justify-center items-start gap-[50px] inline-flex">
            <div className="justify-start items-start gap-[45px] inline-flex">
              <div class="w-[30px] h-[30px] relative">
                <div class="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <h3 className="text-white text-[25px] font-bold font-customFont">
                  Comprehensive Confirmation and Receipts
                </h3>
                <br />
                <br />
                <p className="text-white text-[25px] font-light font-customFont">
                  {' '}
                  Once they submit a payment, Customers see a confirmation page with the statuses of
                  all their Transactions. Customers also receive an email receipt with payment
                  details and Invoice breakdowns.
                </p>
              </div>
            </div>
          </article>
        </section>
        <div className="w-[1054px] max-[1054px]:w-[300px] h-[0px] border border-black"></div>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1240px]:grid">
          <figure className="h-[466.504px] w-[719px] flex-shrink-0 max-[1240px]:hidden">
            <img
              src={RecurringInvoicing}
              alt="Screenshot for recurring billing with invoicing"
            ></img>
          </figure>
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              <span className="font-bold text-[#C0F]">Recurring Billing</span> utilizes Invoices to
              regularly bill your customers according to a custom schedule.
            </h2>
            <Link
              to="/features/recurringBilling"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452A3] learn1"
            >
              <p
                className="text-xl font-medium text-white font-customFont"
                to="/features/recurringBilling"
              >
                Learn More
              </p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="h-[466.504px] w-[719px] flex-shrink-0 min-[1240px]:hidden">
            <img
              src={RecurringInvoicing}
              alt="Screenshot for recurring billing with invoicing"
            ></img>
          </figure>
        </section>
        <section className="h-[157px] max-md:h-auto flex-col justify-start items-center gap-[25px] inline-flex">
          <h1 className="self-stretch text-center text-black text-[45px] font-medium font-customFont">
            Ready to get started?
          </h1>
          <Link
            to="/bookDemo"
            className="flex items-start gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] bookDemo"
          >
            <p className="text-xl font-medium text-center text-white font-customFont">
              Book a demo today!
            </p>
            <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
              <svg
                className="absolute arrowNav top-2"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
