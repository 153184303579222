import Footer from './Footer';
import logo from './img/iQPro+ Logo.png';
import { useRef, useState } from 'react';
import { Select, Input, Form, Alert } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import background from './img/bookDemoTextBackground.png';

export default function BookDemo() {
  const container = useRef();
  const [form] = Form.useForm();
  const [alert, throwAlert] = useState(<></>);
  const [helpText, setHelpText] = useState(
    'I’m new to Basys. I want to learn about accepting cards with iQ Pro+.'
  );
  const handleChange = (value) => {
    if (value === 'New Merchant') {
      setHelpText('I’m new to Basys. I want to learn about accepting cards with iQ Pro+.');
    } else if (value === 'Current Merchant') {
      setHelpText('I’m a current Basys customer and I want to learn more about iQ Pro+.');
    } else if (value === 'Integrations Partner') {
      setHelpText('I want to learn about integrating iQ Pro+ with my company’s software.');
    } else {
      setHelpText(' ');
    }
  };
  const onFinish = (values) => {
    document.getElementById('submitRequest').disabled = true;
    console.log('Email sending...');
    let date = new Date();
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    const userDate = date.toISOString().split('T')[0];
    values.date = userDate;
    console.log(values);
    axios
      .post(`${process.env.REACT_APP_EMAIL_FUNCTION_URL}/api/sendleademail`, values)
      .then((res) => {
        if (res.status === 200) {
          console.log('success');
          throwAlert(
            <Alert
              message="Thank you for reaching out."
              description={
                <>
                  <p>Your request will be sent to one of our representatives.</p>
                  <br />
                  <Link to="/" className="flex items-center justify-center px-4 py-2">
                    <p className="font-customFont text-black text-[20px] font-medium underline">
                      Return to Home
                    </p>
                  </Link>
                </>
              }
              type="success"
              showIcon
              closable
            />
          );
          form.resetFields();
        }
      })
      .catch(function (error) {
        throwAlert(
          <Alert
            message="There was an error."
            description={error.message}
            type="error"
            showIcon
            closable
          />
        );
        console.error(error);
        document.getElementById('submitRequest').disabled = false;
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <main
        ref={container}
        className="w-full h-full px-[200px] max-[681px]:px-[60px] py-10 flex-col justify-start items-center gap-[100px] inline-flex"
      >
        <section className="flex-col w-full justify-start items-start gap-[68px] inline-flex">
          <a className="w-[173.717px] h-[62px] relative" id="logo" href="/">
            <img src={logo} alt="iQ Pro+ Logo" />
          </a>
        </section>
        <section className="h-auto w-full self-stretch flex-col justify-center items-center gap-[60px] inline-flex">
          <h1 className="text-black text-[45px] self-stretch font-medium font-customFont">
            We’d love to work with you.
          </h1>
          <article className="justify-start items-center gap-[100px] inline-flex max-[1000px]:grid">
            <section
              className="flex w-[428px] max-[1000px]:w-[100%] p-[42px] flex-col justify-between items-start self-stretch rounded-lg backgroundBook"
              style={{
                background: `url(${background}) -38.108px -346.206px / 122.886% 164.356% no-repeat`
              }}
            >
              <h2 className="text-white text-[35px] font-bold font-['Roboto'] leading-[42px]">
                Book a Demo
              </h2>
              <p className="self-stretch text-xl font-light leading-normal text-white font-customFont">
                Whether you're a new merchant or currently processing with Basys, iQ Pro+ gives you
                the power to take your payments to the next level while saving time, money and
                hassle.
              </p>
              <p className="text-white text-[25px] font-normal font-customFont leading-[30px]">
                Focus on your business and we’ll handle the rest.
              </p>
            </section>
            <Form
              form={form}
              id="form"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{ leadType: 'New Merchant' }}
              className="w-[681px] max-[681px]:w-auto h-auto justify-end max-[681px]:justify-center max-[681px]:items-center items-end gap-[35px] grid"
            >
              <div className="h-[25px] flex-col justify-center items-start gap-5 inline-flex">
                <p className="text-xl font-bold font-customFont text-[#23242a]">I am a(n)...</p>
              </div>
              <div className="h-[94px] flex-col justify-start items-start gap-1 inline-flex">
                <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
                  <label
                    htmlFor="role"
                    className="text-[#23242a] text-lg font-medium font-['Inter'] leading-relaxed"
                  >
                    Select an option
                  </label>
                </div>
                <Form.Item name="leadType" className="w-full max-[681px]:w-[323px] mb-0">
                  <Select
                    size="large"
                    id="role"
                    name="leadType"
                    onChange={handleChange}
                    style={{
                      alignSelf: 'flex-start'
                    }}
                    options={[
                      {
                        value: 'New Merchant',
                        label: 'New Merchant'
                      },
                      {
                        value: 'Current Merchant',
                        label: 'Current Merchant'
                      },
                      {
                        value: 'Integrations Partner',
                        label: 'Integrations Partner'
                      }
                    ]}
                  />
                </Form.Item>
                <div className="flex flex-col items-start self-stretch justify-start h-5">
                  <p className="self-stretch text-[#23242a] text-base font-normal font-['Roboto'] leading-tight tracking-wide">
                    {helpText}
                  </p>
                </div>
              </div>
              <div className="justify-start items-start gap-[35px] inline-flex max-[681px]:grid">
                <div className="inline-flex flex-col items-start justify-start gap-1">
                  <div className="inline-flex items-center self-stretch justify-start gap-1">
                    <label
                      htmlFor="fname"
                      className="text-lg font-medium font-['Inter'] leading-relaxed text-[#23242a]"
                    >
                      First Name
                    </label>
                  </div>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please add first name'
                      }
                    ]}
                  >
                    <Input id="fname" name="firstName" size="large" className="w-[323px]" />
                  </Form.Item>
                </div>
                <div className="inline-flex flex-col items-start justify-start gap-1">
                  <div className="inline-flex items-center self-stretch justify-start gap-1">
                    <label
                      htmlFor="lname"
                      className="text-lg font-medium font-['Inter'] leading-relaxed text-[#23242a]"
                    >
                      Last Name
                    </label>
                  </div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please add last name'
                      }
                    ]}
                  >
                    <Input id="lname" name="Last Name" size="large" className="w-[323px]" />
                  </Form.Item>
                </div>
              </div>
              <div className="inline-flex flex-col items-start justify-start gap-1">
                <label
                  htmlFor="cname"
                  className="text-[#23242a] text-lg font-medium font-['Inter'] leading-relaxed"
                >
                  Company Name
                </label>
                <Form.Item
                  name="companyName"
                  className="w-full max-[681px]:w-[323px] mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Please add company name'
                    }
                  ]}
                >
                  <Input id="cname" name="Company Name" size="large" />
                </Form.Item>
              </div>
              <div className="justify-start items-start gap-[35px] inline-flex max-[681px]:grid">
                <div className="inline-flex flex-col items-start justify-start gap-1">
                  <div className="inline-flex items-center self-stretch justify-start gap-1">
                    <label
                      htmlFor="email"
                      className="text-lg font-medium font-['Inter'] leading-relaxed text-[#23242a]"
                    >
                      Email
                    </label>
                  </div>
                  <Form.Item
                    name="email"
                    validateTrigger="onBlur"
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Please add a valid email'
                      }
                    ]}
                  >
                    <Input id="email" name="email" size="large" className="w-[323px]" />
                  </Form.Item>
                </div>
                <div className="inline-flex flex-col items-start justify-start gap-1">
                  <div className="inline-flex items-center self-stretch justify-start gap-1">
                    <label
                      htmlFor="pnumber"
                      className="text-lg font-medium font-['Inter'] leading-relaxed text-[#23242a]"
                    >
                      Phone Number
                    </label>
                  </div>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: 'Please add a phone number'
                      }
                    ]}
                  >
                    <Input id="pnumber" name="phoneNumber" size="large" className="w-[323px]" />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                shouldUpdate
                className="self-end mb-0 justify-self-end max-[681px]:justify-self-center"
              >
                <button
                  type="submit"
                  id="submitRequest"
                  className="flex w-[246px] py-2 px-4 justify-center items-center gap-4 rounded-[20px] bg-[#006]"
                >
                  <p className="font-customFont text-white text-[20px] font-medium">Submit</p>
                  <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                    <svg
                      className="absolute arrowNav top-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                    >
                      <path
                        d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </button>
              </Form.Item>
            </Form>
          </article>
        </section>
        {alert}
      </main>
      <Footer />
    </>
  );
}
