import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { styled } from '@mui/material/styles';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />
    }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: theme.palette.secondary.main
  }
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.fontColor.main,
  '&.Mui-selected': {
    color: theme.palette.fontColor.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function TabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const OnboardingTraining = () => {
  const [value, setValue] = useState(0);
  const handleChange = (eventt, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid>
      <Navbar />
      {/* TITLE */}
      <Grid align="center">
        <Typography variant="h1" fontWeight="500" color="secondary" className="onboarding-title">
          Training Videos
        </Typography>
      </Grid>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {/* TABS */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="training video tabs"
              centered
            >
              <StyledTab label="Customers" {...TabProps(0)} />
              <StyledTab label="Gateway" {...TabProps(1)} />
              <StyledTab label="Invoicing" {...TabProps(2)} />
              <StyledTab label="Mobile App" {...TabProps(3)} />
            </StyledTabs>
          </Box>
          {/* CUSTOMER */}
          <TabPanel value={value} index={0}>
            {/* Create Customer */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5} sx={{ pr: { xs: 0, md: 5 } }}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500">
                    Create Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>Select &apos;Create Customer&apos;</li>
                    <li>
                      Select a Payment Method to enter for the customer (Card, ACH, or No Payment
                      Method)
                    </li>
                    <li>
                      A custom Customer ID can be entered when the customer is created, but cannot
                      be changed afterwards
                    </li>
                    <li>A Description can be entered for the customer if applicable</li>
                    <li>Enter Billing and/or Shipping Contact information</li>
                    <li>
                      Once the customer information is entered, press &apos;Create Customer&apos; to
                      create the customer
                    </li>
                    <li>
                      A &apos;Customer Created&apos; confirmation message will appear at the top of
                      the page once the customer is created
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/834134296?h=8b779d1caa&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Create Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* View Customer */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={5}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164733?h=9896560a9c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="View Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    View Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>From the Customer List, locate the customer using the search filters</li>
                    <li>Select the eye icon to view the customer</li>
                    <li>The customer record opens in view mode</li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164733?h=9896560a9c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="View Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Edit Customer */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Edit Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>From the Customer List, locate the customer using the search filters</li>
                    <li>Select the pencil icon to open the customer in edit mode</li>
                    <li>The customer record opens with the fields in edit mode</li>
                    <li>Make changes to any of the fields such as notes</li>
                    <li>Press &apos;Update&apos; to save changes</li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/834164674?h=e75ef6a880&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                  title="Edit Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Delete Customer */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={5}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164653?h=97698d73a6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Delete Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Delete Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>From the Customer List, locate the customer using the search filters</li>
                    <li>Select the red trash can icon to delete the customer</li>
                    <li>A confirmation popup will display to confirm the customer deletion</li>
                    <li>Press &apos;Yes, Delete This Customer&apos; to delete the customer</li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164733?h=9896560a9c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Delete Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Customer - Add Payment */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5} sx={{ pr: { xs: 0, md: 5 } }}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Customer - Add Payment Method
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>From the Customer List, locate the customer using the search filters</li>
                    <li>Select the eye icon to view the customer</li>
                    <li>The customer record opens in view mode </li>
                    <li>Select the &apos;Create a Payment Method&apos; button</li>
                    <li>Select whether to add a Card or ACH payment method</li>
                    <li>Enter the applicable payment method fields</li>
                    <li>Choose if the payment method will be the Default Payment Method</li>
                    <li>
                      Once the Card or ACH information is entered, press &apos;Submit&apos; to save
                      the payment method
                    </li>
                    <li>The payment method will display in the Payments list</li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/834164631?h=dc39af7cd3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Customer Add Payment"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Customer - Add Address */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164612?h=bc06b01560&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Customer - Add Address"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={6}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Customer - Add Address
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Customers</li>
                    <li>From the Customer List, locate the customer using the search filters</li>
                    <li>Select the eye icon to view the customer</li>
                    <li>The customer record opens in view mode</li>
                    <li>Select the &apos;Address&apos; tab</li>
                    <li>Select &apos;Create an Address&apos;</li>
                    <li>The address form displays</li>
                    <li>
                      Choose if this will be the default address for billing, shipping, both, or
                      neither
                    </li>
                    <li>Enter the address information</li>
                    <li>Press &apos;Submit&apos; to create the address</li>
                    <li>
                      A &apos;Success&apos; message displays at the top of the screen to confirm the
                      address was created
                    </li>
                    <li>The address will display in the customer&apos;s address list</li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164612?h=bc06b01560&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Customer - Add Address"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </TabPanel>
          {/* GATEWAY */}
          <TabPanel value={value} index={1}>
            {/* Gateway - Process Transaction on Virtual Terminal from Customer Record */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ py: 5 }}
            >
              <Grid container item xs={10} lg={5} sx={{ pr: { xs: 0, md: 5 } }}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Gateway - Process Transaction on Virtual Terminal from Customer Record
                  </Typography>
                </Grid>
                <Grid item lg={4}></Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to the Virtual Terminal</li>
                    <li>Select the Customer tab on the Virtual Terminal</li>
                    <li>
                      Click &apos;Select&apos; next to the customer to select the customer for the
                      transaction
                    </li>
                    <li>
                      The customer&apos;s payment methods, billing addresses, and shipping addresses
                      display in dropdowns. Select the payment method and corresponding billing and
                      shipping addresses for the transaction
                    </li>
                    <li>
                      Next, enter the dollar amount for the transaction and any other applicable
                      fields
                    </li>
                    <li>Then, press &apos;Submit&apos; to process the transaction</li>
                    <li>Choose if the payment method will be the Default Payment Method</li>
                    <li>
                      Once the Card or ACH information is entered, press &apos;Submit&apos; to save
                      the payment method
                    </li>
                    <li>The payment method will display in the Payments list</li>
                  </ol>
                </Grid>
              </Grid>

              <Grid item>
                <iframe
                  src="https://player.vimeo.com/video/834164746?h=08fc6054f4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Gateway - Process Transaction on Virtual Terminal from Customer Record"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
            {/* Gateway - Reporting - Settlements */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/911373533?h=6215e8a4a0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Gateway - Reporting - Settlements"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Gateway - Reporting - Settlements
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Once logged in as a gateway user, select ‘Reporting’ from the left navigation
                      menu
                    </li>
                    <li>Then, select ‘Settlements’ from the left navigation menu</li>
                    <li>The Cumulative Totals row displays the totals of the following columns:</li>
                    <ul>
                      <li>Captured</li>
                      <li># Trans (# of Transactions)</li>
                      <li>Net</li>
                      <li>Base</li>
                      <li>Credit</li>
                      <li>
                        Note: If the settlements include surcharges or payment adjustments, a column
                        for surcharge or payment adjustment will display
                      </li>
                    </ul>
                    <li>
                      Select the &apos;Download&apos; button to export the results summary in either
                      CSV or PDF format
                    </li>
                    <ul>
                      <li>Note: Both download options include the totals row</li>
                    </ul>
                    <li>
                      The settlement results display with an action icon next to each. Click the
                      magnifying glass action icon to view details of one of the settlements
                    </li>
                    <li>
                      The Settlement Details will be displayed, including the settlement information
                      and transaction details
                    </li>
                    <li>
                      Clicking on &apos;Transaction Search&apos; to view the Transactions List with
                      the &apos;Settlement Batch Id&apos; pre-populated in the search bar
                    </li>
                    <ul>
                      <li>
                        From the Transactions List, additional actions can be taken on the
                        transactions in the settlement
                      </li>
                    </ul>
                    <li>
                      From Settlement Details, select the &apos;Download&apos; button to export
                      transaction level details of the settlement in either CSV or PDF format.
                    </li>
                    <ul>
                      <li>Note: Both download options include the totals row</li>
                    </ul>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164717?h=51d5074c0a&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                  title="Gateway - Reporting - Settlements"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Gateway - Select Gateway and Toggle Between Gateways
             */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Gateway - Select Gateway and Toggle Between Gateways
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      If the email address is associated with more than one gateway, a gateway
                      dropdown displays upon logging in
                    </li>
                    <li>Select which gateway to log into</li>
                    <li>
                      Once logged into a gateway, select the Switch Gateway dropdown from the top of
                      the screen and select a different gateway
                    </li>
                    <li>
                      This will logout of the current gateway and log into the selected gateway
                      allowing you to easily toggle between each of your gateways
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/834164693?h=fe86e688cc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Gateway - Select Gateway and Toggle Between Gateways"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </TabPanel>
          {/* INVOICING */}
          <TabPanel value={value} index={2}>
            {/* Managing Invoices */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ py: 5 }}
            >
              <Grid container item xs={10} lg={5} sx={{ pr: { xs: 0, md: 5 } }}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Manage Invoices
                  </Typography>
                </Grid>
                <Grid item lg={4}></Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to the Invoices tab</li>
                    <li>
                      Use the Global Search feature to search for specific invoices. Enter any
                      relevant criteria, such as the Invoice ID, Customer Name, Amount and more to
                      return invoices matching your search criteria
                    </li>
                    <ul>
                      <li>
                        Click the calendar icon to search invoices by the Due Date or Created Date
                      </li>
                    </ul>
                    <li>
                      Sort invoices by clicking the arrow next to column names to arrange by
                      Customer Name, Amount, Due Date, and more
                    </li>
                    <ul>
                      <li>
                        Click the Status filter to quickly sort invoices based on their status
                      </li>
                    </ul>
                    <li>
                      Several actions are available (based on the invoice&apos;s status) to assist
                      your invoicing workflows. Click the three dots menu under Actions to:
                    </li>
                    <ul>
                      <li>View invoice</li>
                      <li>View invoice as PDF</li>
                      <li>Edit invoice</li>
                      <li>Clone invoice</li>
                      <li>Cancel invoice</li>
                      <li>View transactions</li>
                      <li>Delete invoice</li>
                      <li>View customer</li>
                    </ul>
                  </ol>
                </Grid>
              </Grid>

              <Grid item>
                <iframe
                  src="https://player.vimeo.com/video/911373551?h=9ce634f1fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Managing Invoices"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
            {/* Creating an Invoice */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/911373516?h=7ba6538350&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Creating an Invoice"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Creating an Invoice
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Login to iQ Pro+ as a gateway user and navigate to Invoices tab</li>
                    <li>Click on &apos;Create Invoice&apos; to start the creation process</li>
                    <li>Step 1: Add a Customer</li>
                    <ol>
                      <li>
                        Enter &apos;Bill To&apos; and &apos;Ship To&apos; details for the invoice
                        manually, or by clicking the &apos;Select a Customer&apos; button to choose
                        from an existing customer. The &apos;Bill To&apos; details added will be
                        displayed on the customer&apos;s invoice
                      </li>
                      <li>
                        You can choose to require billing or shipping details on payment by checking
                        the appropriate box
                      </li>
                      <li>
                        Choose to send an invoice notification email to the customer by selecting
                        &apos;Email&apos; from the &apos;Send invoice via&apos; dropdown. The email
                        in the &apos;Bill To&apos; details will be used to deliver the invoice
                        email, but additional emails can be added by clicking &apos;Add CC/BCC&apos;
                        at the bottom of the page
                      </li>
                      <li>Click ‘Next Step’ to set up your invoice</li>
                    </ol>
                    <li>Step 2: Set Up Invoice</li>
                    <ol>
                      <li>
                        Configure the details of your invoice. Enter the &apos;Invoice Title&apos;
                        which will be used in the subject of the notification email sent to your
                        customer
                      </li>
                      <li>
                        Specify the Terms by selecting from the dropdown, and the Invoice Date
                        through the calendar
                      </li>
                      <li>
                        Toggle the Available Payment Types to choose your customer&apos;s payment
                        options, then select the corresponding processor.
                      </li>
                      <li>
                        You can display a message on the invoice by writing in the
                        &apos;Message&apos; text field
                      </li>
                      <li>Click &apos;Next Step&apos; to add line items to your invoice</li>
                    </ol>
                    <li>Step 3: Add Line Items</li>
                    <ol>
                      <li>From the Summary tab, select the &apos;Download&apos; button</li>
                      <li>
                        Populate the line items of your invoice. Enter &apos;Item Name,&apos;
                        &apos;Item Description,&apos; &apos;Quantity&apos; and &apos;Unit
                        Price&apos; into the corresponding fields
                      </li>
                      <li>
                        Add a discount through the &apos;Discount&apos; field or add shipping costs
                        by toggling the &apos;Enable Advanced Fields&apos; option as needed
                      </li>
                      <li>
                        Toggle the &apos;Taxable&apos; option to enter any tax rate(s) applicable to
                        your item. Add additional line items as necessary by clicking the &apos;Add
                        Line Item&apos; button or remove line items by clicking the &apos;X&apos; in
                        top right
                      </li>
                      <li>
                        Click &apos;Create&apos; and select whether you want to Save (as a draft) or
                        Send
                      </li>
                    </ol>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/834164717?h=51d5074c0a&badge=0&autopause=0&player_id=0&app_id=58479/embed"
                  title="Gateway - Reporting - Settlements"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
            {/* Invoice Email Notifications, Payment, and Receipts
             */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Invoice Email Notifications, Payment, and Receipts
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      To send an email to customers for newly created invoices, choose
                      &apos;Email&apos; from the dropdown below &apos;Send invoice via&apos; during
                      Step 1 of the invoice creation process
                    </li>
                    <ul>
                      <li>
                        Note: The customer&apos;s billing email address serves as the primary
                        recipient. Add more recipients by clicking &apos;Add CC/BCC&apos;
                      </li>
                    </ul>
                    <li>
                      After creating and sending the invoice, an email notification will be
                      delivered to the specified email(s), including a summary of the charges, a PDF
                      of the invoice and a &apos;Pay Now&apos; link
                    </li>
                    <li>
                      Clicking the link will direct the customer to the payment page. Displayed is
                      an invoice summary and a payment section for customers to enter their Card or
                      Bank Account details (depending on the payment method(s) configured for the
                      invoice)
                    </li>
                    <li>
                      Customers can &apos;Pay In Full&apos; or pay a partial amount by clicking
                      &apos;Pay Other Amount&apos; and entering their desired amount within the
                      field
                    </li>
                    <li>
                      After clicking &apos;Submit Payment&apos; the customer will be brought to a
                      confirmation screen where the payment amount, balance remaining and an option
                      to view the updated invoice as a PDF are displayed
                    </li>
                    <li>
                      The customer will also be sent a receipt, detailing the transaction
                      information and an overview of the invoice. A PDF of the receipt is also
                      included
                    </li>
                    <ul>
                      <li>
                        If the invoice was not paid in full, a &apos;Pay Remaining Balance&apos;
                        link will be included below the invoice overview for the customer to make
                        additional payments
                      </li>
                    </ul>
                    <li>
                      To resend a receipt, locate the invoice transaction by searching the
                      Transactions List by Invoice ID, clicking the &apos;View transaction&apos;
                      action from the Invoices table, or by viewing the invoice and clicking
                      &apos;View Transaction History&apos; in the bottom left corner
                    </li>
                    <li>
                      Send the transaction receipt directly by clicking the &apos;Email&apos; action
                      and populating the email field, or by opening the transaction by clicking
                      &apos;Transaction Info&apos; and then &apos;Email Receipt&apos; button at the
                      top of the transactions page
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/911587713?h=ecb3cf496f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Invoice Email Notifications, Payment, and Receipts"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ py: 5 }}
            >
              <Grid container item xs={10} lg={5} sx={{ pr: { xs: 0, md: 5 } }}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Run a Transaction
                  </Typography>
                </Grid>
                <Grid item lg={4}></Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click <b>Make a Payment</b> on the home page or select <b>Payment</b> from the
                      navigation bar.
                    </li>
                    <li>Specify the transaction type and amount.</li>
                    <li>
                      Add the payment method (Credit Card or Bank Account) and select the
                      appropriate processor.
                    </li>
                    <li>
                      Add any adjustments to the payment. You can select percentage or exact amount.
                    </li>
                    <li>
                      Add taxes to the transaction by selecting <b>Add Tax to Total</b> to add a
                      flat percentage or selecting <b>Tax Override</b> and specifying a specific
                      amount. Select <b>Tax Exempt</b> for tax exempt transactions.
                    </li>
                    <li>
                      Click the <b>Next</b> button.
                    </li>
                    <li>
                      Specify the transaction details in the <b>Transaction Details</b> section.
                    </li>
                    <li>
                      Specify all the fields in the <b>Billing Address</b> and{' '}
                      <b>Shipping Address</b> sections.
                    </li>
                    <li>
                      Click the <b>Next</b> button.
                    </li>
                    <li>
                      To send a receipt with the transactions, select the <b>Send Receipt</b>{' '}
                      checkbox and specify an email in the <b>Email</b> textbox.
                    </li>
                    <li>
                      Click Pay Total to process the payment.
                      <ol>
                        <ui>
                          NOTE: To be PCI compliant, a fake card is used for the purposes of this
                          tutorial.
                        </ui>
                      </ol>
                    </li>
                  </ol>
                </Grid>
              </Grid>

              <Grid item>
                <iframe
                  src="https://player.vimeo.com/video/985726450?h=6455d42d06&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Run a Transaction"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985725203?h=0af09cd21f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Create Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Create Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click <b>Add a Customer</b> on the home page or select the + icon while in the
                      Customers tab.
                    </li>
                    <li>
                      Specify all the required fields under the <b>Customer Details</b>,{' '}
                      <b>Billing Address</b> and <b>Shipping Address</b> sections.
                    </li>
                    <li>
                      Under Add Payment Method, specify credit card information, bank account
                      information or no payment method.
                    </li>
                    <li>
                      Click <b>Add Customer</b>.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985725203?h=0af09cd21f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Create Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Edit Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click <b>Find a Customer</b> on the home page or select <b>Customer</b> from
                      the navigation bar.
                    </li>
                    <li>Click the pencil icon next to the customer you want to edit.</li>
                    <li>
                      Edit the customer’s name and description and click <b>Update Details</b>.
                    </li>
                    <li>
                      To add an address, select the <b>Addresses</b> section and click the <b>+</b>{' '}
                      icon. Specify the address and click <b>Add Address</b>.
                    </li>
                    <li>
                      To add a payment method, select the <b>Payment Methods</b> section and click
                      the <b>+</b> icon. Specify the payment method and click{' '}
                      <b>Add Payment Method</b>.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/985725588?h=0f3431919b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Edit Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985726214?h=2b02070c8d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Pay with Existing Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Pay with Existing Customer
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click <b>Find a Customer</b> on the home page or select <b>Customer</b> from
                      the navigation bar.
                    </li>
                    <li>
                      Search for the desired customer and click <b>Pay Now</b>.
                    </li>
                    <li>
                      Fill out the payment information. If the customer already has a payment method
                      associated with them, you can select it from the{' '}
                      <b>Select a Payment Method</b> dropdown.
                    </li>
                    <li>
                      Click the <b>Next</b> button.
                    </li>
                    <li>
                      Fill out all the transaction details and billing and shipping information. If
                      the customer already has an address associated with them, you can select if
                      from the <b>Select an Address</b> dropdown in the <b>Billing Address</b> and{' '}
                      <b>Shipping Address</b> sections.
                    </li>
                    <li>
                      Click the <b>Next</b> button.
                    </li>
                    <li>
                      Click <b>Pay Total</b>.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985726214?h=2b02070c8d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Pay with Existing Customer"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    View Transaction
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click <b>Transactions</b> on the navigation bar.
                    </li>
                    <li>
                      Select a transaction from the <b>Transaction List</b>.
                    </li>
                    <li>
                      From here, you can view the details of a transaction. You can select the{' '}
                      <b>Address</b> tab or the <b>Payment</b> tab to view the billing and shipping
                      information and the payment method associated with the transaction,
                      respectively.
                    </li>
                    <li>
                      You can also send a receipt through an email. If an email was already
                      specified when you ran the transaction, click <b>Email Receipt</b> to send a
                      receipt to that email
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/985727070?h=214e347e35&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="View Transaction"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985727119?h=1bf2652079&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Void Transaction"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Void Transaction
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>Click Transactions on the navigation bar.</li>
                    <li>
                      Select a transaction from the <b>Transaction List</b>.
                    </li>
                    <li>
                      Click the <b>Void</b> button.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985727119?h=1bf2652079&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Void Transaction"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid item lg={2}></Grid>
              <Grid item xs={10} lg={5}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Switch Gateway
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Click the gear icon in the upper right corner of the home page to access{' '}
                      <b>Settings</b>.
                    </li>
                    <li>
                      In the <b>Settings Menu</b>, you will see all the gateways you are associated
                      to under the <b>Switch Gateway</b> section.
                    </li>
                    <li>
                      Select the gateway you want to switch to and click <b>Switch Gateway</b>.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid item lg={1}></Grid>
              <Grid item lg>
                <iframe
                  src="https://player.vimeo.com/video/985726993?h=18d5922787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Switch Gateway"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item lg={2}></Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: 'rgba(24, 154, 208, 0.17)',
                py: 5
              }}
            >
              <Grid item lg={2}></Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'none', lg: 'block' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985726093?h=78d35af9eb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Log In with Biometrics"
                  allowFullScreen
                ></iframe>
              </Grid>
              <Grid item xs={10} lg={4}>
                <Grid item>
                  <Typography variant="h2" className="features-h2" fontWeight="500" sx={{ mb: 3 }}>
                    Log In with Biometrics
                  </Typography>
                </Grid>
                <Grid item className="block-list">
                  <ol>
                    <li>
                      Upon logging in for the first time to the iQ Pro mobile app, specify your
                      login credentials and a pop-up window will appear asking to enable biometric
                      login.
                    </li>
                    <li>
                      Click <b>Yes</b> to enable or <b>No</b> to disable. If this is enabled, it
                      will use biometrics the next time you log in.
                    </li>
                    <li>
                      Click the gear icon in the upper right corner of the home page to access
                      Settings.
                    </li>
                    <li>
                      In the <b>Settings Menu</b>, you will see a toggle labeled{' '}
                      <b>Log In Using Biometrics</b>. The app will honor whatever the toggle is set
                      to the next time you log in.
                    </li>
                  </ol>
                </Grid>
              </Grid>
              <Grid
                item
                lg={4}
                sx={{
                  display: { xs: 'block', lg: 'none' }
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/985726093?h=78d35af9eb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  title="Log In with Biometrics"
                  allowFullScreen
                ></iframe>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default OnboardingTraining;
