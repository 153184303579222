import logo from './img/iQPro+ Logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="w-full self-stretch h-[354px] py-[50px] px-[150px] justify-between items-center gap-[60px] inline-flex max-[1055px]:grid max-[1065px]:justify-center">
      <div className="h-[187.64px] flex-col justify-start items-start gap-5 inline-flex">
        <Link to="/" className="w-[252.17px] h-[90px] relative">
          <img src={logo} alt="iQPro Logo" />
        </Link>
        <p className="w-auto text-base font-normal text-black font-customFont">
          © 2024 iQ Pro
          <br />
          iQ Pro is a registered service mark of BASYS Processing. <br />
          All rights reserved. <br />
          <Link to="/terms-of-service">Terms of Service</Link> |{' '}
          <a href="https://basyspro.com/privacy-policy/" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
        </p>
      </div>
      <div className="inline-flex flex-col items-start justify-start h-[254px] max-[882px]:h-auto gap-4 p-4">
        <h2 className="text-black h-auto text-[25px] font-semibold font-customFont">Features</h2>
        <nav className="justify-start items-start gap-[62px] inline-flex max-[681px]:grid">
          <div className="w-auto flex-col justify-start items-start gap-[15px] inline-flex">
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/terminal"
            >
              Terminal
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/simplePay"
            >
              Simple Pay
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/recurringBilling"
            >
              Recurring Billing
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/reporting"
            >
              Reporting
            </Link>
          </div>
          <div className="w-auto flex-col justify-start items-start gap-[15px] inline-flex">
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/customers"
            >
              Customers
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/invoicing"
            >
              Invoicing
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/mobileApp"
            >
              Mobile App
            </Link>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/features/physicalTerminals"
            >
              Physical Terminals
            </Link>
          </div>
        </nav>
      </div>
      <div className="inline-flex flex-col items-start justify-start h-[254px] gap-4 p-4">
        <h2 className="text-black text-[25px] font-semibold font-customFont">Resources</h2>
        <nav className="w-auto justify-start items-start gap-[15px] inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-[15px] inline-flex">
            <a
              className="self-stretch text-base font-normal text-black font-customFont"
              href="https://developer.basyspro.com/"
              target="_blank"
              rel="noreferrer"
            >
              Developer Docs
            </a>
            <a
              className="self-stretch text-base font-normal text-black font-customFont"
              href="https://basyspro.com/equipment/qrg/qrg-iqpro/"
              target="_blank"
              rel="noreferrer"
            >
              Quick Reference Guide
            </a>
            <Link
              className="self-stretch text-base font-normal text-black font-customFont"
              to="/support"
            >
              Frequently Asked Questions
            </Link>
          </div>
        </nav>
      </div>
    </footer>
  );
}
