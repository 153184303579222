import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import physicalTerminalImage from '../img/physicalTerminalImage.png';
import tablet from '../img/physicalTerminalTablet.png';
import PAXA35 from '../img/PAX A35.png';
import PAXA80 from '../img/PAX A80.png';
import PAXA77 from '../img/PAX A77.png';
import PAXA920 from '../img/PAX A920_A920 Pro.png';
import { Link } from 'react-router-dom';

export default function PhysicalTerminals() {
  return (
    <>
      <MobileNavigation />
      <main className="w-full h-full px-[200px] py-10 flex-col justify-start items-center gap-[100px] inline-flex">
        <Header />

        <section className="inline-flex items-center self-stretch justify-center gap-4 max-[1516px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-6 max-[1516px]:items-center">
            <h2 className="text-[#4452A3] text-[25px] font-light font-customFont">
              PHYSICAL TERMINALS - COMING SOON
            </h2>
            <h1 className="text-black text-[45px] font-medium font-customFont">
              <span className="font-semibold text-[#C0F]">In-person</span> payments.
            </h1>
            <p className="text-black text-[25px] font-light font-customFont">
              Process card transactions wherever your business takes you through iQ Pro+ compatible
              terminals.
            </p>
            <Link
              to="/bookDemo"
              className="flex items-center justify-center gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] headerDemo"
            >
              <p className="text-xl font-medium text-white font-customFont">Book a Demo</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[476.32px] h-[423.91px] flex-shrink-0">
            <img
              src={physicalTerminalImage}
              alt="physical terminal with tapping a card and a mockup on how you see terminals in iQ Pro+"
            />
          </figure>
        </section>
        <section className="justify-start items-center gap-[60px] inline-flex">
          <figure className="w-[655.21px] h-[546.09px] flex-shrink-0">
            <img src={tablet} alt="Status of physical terminals in a tablet mode." />
          </figure>
          <article className="inline-flex flex-col items-start justify-center gap-6">
            <h2 className="text-black text-[45px] font-medium font-customFont">
              <span className="text-[#C0F] font-semibold">Track</span> payment status
            </h2>
            <p className="text-[25px] font-light">
              Get real-time updates on your terminal transactions for you and your customer's peace
              of mind.
            </p>
          </article>
        </section>
        <section className="flex w-full max-md:w-auto px-[122px] py-[100px] flex-col items-start gap-[50px] bg-[url('./img/softwarePartners.png')] bg-cover bg-bottom md:rounded-3xl">
          <h1 className="text-white text-[45px] font-medium font-customFont">
            What physical terminals are compatible with iQ Pro+?
          </h1>
          <article className="justify-center items-center gap-[60px] inline-flex max-[1482px]:grid">
            <section className="w-full flex-col justify-start items-start gap-[60px] inline-flex">
              <div className="flex-col justify-start items-start gap-[25px] inline-flex">
                <div className="justify-center items-center gap-[25px] inline-flex">
                  <div className="w-[30px] h-[30px] relative">
                    <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_686_3709)">
                          <mask
                            id="mask0_686_3709"
                            style={{ maskType: 'luminance' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="30"
                            height="31"
                          >
                            <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_686_3709)">
                            <path
                              d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                              fill="white"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_686_3709">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(0 0.0917969)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <h2 className="text-white text-[45px] font-medium font-customFont">PAX A35</h2>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  The world's first Android pinpad designed for multilane.
                </p>
              </div>
              <div className="flex-col justify-center items-center gap-[25px] inline-flex">
                <ul className="self-stretch text-white text-[25px] font-light font-customFont list-disc">
                  <li>Powered by Android 10</li>
                  <li>Power over Ethernet</li>
                  <li>4" capacitive touch screen</li>
                  <li>LAN + WiFi (2.4Ghz/5Ghz)</li>
                  <li>Chip & PIN + contactless + Magnetic stripe</li>
                  <li>PIN-on-Glass also feasible</li>
                  <li>0.3MP or 5MP front camera</li>
                  <li>PCI PTS 6.x</li>
                </ul>
              </div>
            </section>
            <img
              className="w-[390.13px] h-[470px] justify-self-center"
              src={PAXA35}
              alt="Rendering of a PAX A35"
            />
          </article>
          <article className="justify-center items-center gap-[116px] inline-flex max-[1482px]:grid">
            <img
              className="w-[224.93px] h-[470px] max-[1482px]:hidden"
              src={PAXA77}
              alt="Rendering of a PAX A77"
            />
            <section className="w-full flex-col justify-start items-start gap-[60px] inline-flex">
              <div className="flex-col justify-start items-start gap-[25px] inline-flex">
                <div className="justify-center items-center gap-[25px] inline-flex">
                  <div className="w-[30px] h-[30px] relative">
                    <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_686_3709)">
                          <mask
                            id="mask0_686_3709"
                            style={{ maskType: 'luminance' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="30"
                            height="31"
                          >
                            <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_686_3709)">
                            <path
                              d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                              fill="white"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_686_3709">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(0 0.0917969)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <h2 className="text-white text-[45px] font-medium font-customFont">PAX A77</h2>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Secure payment acceptance and professional barcode scanner in a stylish,
                  smartphone design.
                </p>
              </div>
              <div className="flex-col justify-center items-center gap-[25px] inline-flex">
                <ul className="self-stretch text-white text-[25px] font-light font-customFont list-disc">
                  <li>Android 10 OS</li>
                  <li>4G + WiFi</li>
                  <li>Contactless + Chip & PIN + magstripe</li>
                  <li>5.5-inch color touchscreen</li>
                  <li>3.8V/5150mAh rechargeable battery</li>
                  <li>2MP front & 5MP rear camera with option to upgrade</li>
                  <li>USB type-C charging port</li>
                  <li>PCI PTS 6.x. SRED</li>
                </ul>
              </div>
            </section>
            <img
              className="w-[224.93px] h-[470px] min-[1482px]:hidden justify-self-center"
              src={PAXA77}
              alt="Rendering of a PAX A77"
            />
          </article>
          <article className="justify-center items-center gap-[60px] inline-flex max-[1482px]:grid">
            <section className="flex-col justify-start items-start gap-[60px] inline-flex">
              <div className="flex-col justify-start items-start gap-[25px] inline-flex">
                <div className="justify-center items-center gap-[25px] inline-flex">
                  <div className="w-[30px] h-[30px] relative">
                    <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_686_3709)">
                          <mask
                            id="mask0_686_3709"
                            style={{ maskType: 'luminance' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="30"
                            height="31"
                          >
                            <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_686_3709)">
                            <path
                              d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                              fill="white"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_686_3709">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(0 0.0917969)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <h2 className="text-white text-[45px] font-medium font-customFont">PAX A80</h2>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  The perfect countertop solution for small & mid-sized businesses, combining the
                  potential of SmartPOS with the ease of use of traditional desktop terminals.
                </p>
              </div>
              <div className="flex-col justify-center items-center gap-[25px] inline-flex">
                <ul className="self-stretch text-white text-[25px] font-light font-customFont list-disc">
                  <li>Android 6.0, 7.1 or 10.0</li>
                  <li>Cortex A53 processor</li>
                  <li>4G/3G + WiFi + Ethernet + dial</li>
                  <li>Contactless + chip & PIN + Magstripe</li>
                  <li>4-inch HD touch screen</li>
                  <li>Physical keypad</li>
                  <li>Camera</li>
                  <li>Printer</li>
                  <li>Optional Battery</li>
                  <li>PCI PTS 5.x or 6.x</li>
                </ul>
              </div>
            </section>
            <img
              className="w-[259.35px] h-[470px] justify-self-center"
              src={PAXA80}
              alt="Rendering of a PAX A80"
            />
          </article>
          <article className="justify-center items-center gap-[116px] inline-flex max-[1482px]:grid">
            <img
              className="w-[376.96px] h-[470px] max-[1482px]:hidden"
              src={PAXA920}
              alt="Rendering of a PAX A920 Pro"
            />
            <section className="w-full flex-col justify-start items-start gap-[60px] inline-flex">
              <div className="flex-col justify-start items-start gap-[25px] inline-flex">
                <div className="justify-center items-center gap-[25px] inline-flex">
                  <div className="w-[30px] h-[30px] relative">
                    <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="31"
                        viewBox="0 0 30 31"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_686_3709)">
                          <mask
                            id="mask0_686_3709"
                            style={{ maskType: 'luminance' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="30"
                            height="31"
                          >
                            <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                          </mask>
                          <g mask="url(#mask0_686_3709)">
                            <path
                              d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                              fill="white"
                            />
                          </g>
                        </g>
                        <defs>
                          <clipPath id="clip0_686_3709">
                            <rect
                              width="30"
                              height="30"
                              fill="white"
                              transform="translate(0 0.0917969)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <h2 className="text-white text-[45px] font-medium font-customFont">
                    PAX A920/ A920 Pro
                  </h2>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Designed for all vertical markets and special projects, combining the full
                  features of an Android tablet with a powerful POS payment terminal in a sleek and
                  compact design.
                </p>
              </div>
              <div className="flex-col justify-center items-center gap-[25px] inline-flex">
                <ul className="self-stretch text-white text-[25px] font-light font-customFont list-disc">
                  <li>Powered by Android 8.1 or 10</li>
                  <li>Cortex A53 processor</li>
                  <li>5.5" capacitive touchscreen</li>
                  <li>4G + WiFi</li>
                  <li>Contactless + chip & PIN + magstripe</li>
                  <li>PIN on glass technology</li>
                  <li>5MP front + 8MP rear camera</li>
                  <li>5150mAh / 3.7V long-lasting battery</li>
                  <li>Fast-speed printer</li>
                  <li>PCI PTS 5.x (Android 8.1) or 6.x (Android 10) SRED</li>
                </ul>
              </div>
            </section>
            <img
              className="w-[376.96px] h-[470px] justify-self-center min-[1482px]:hidden"
              src={PAXA920}
              alt="Rendering of a PAX A920 Pro"
            />
          </article>
        </section>
        <section className="h-[157px] max-md:h-auto flex-col justify-start items-center gap-[25px] inline-flex">
          <h1 className="self-stretch text-center text-black text-[45px] font-medium font-customFont">
            Ready to get started?
          </h1>
          <Link
            to="/bookDemo"
            className="flex items-start gap-4 px-4 py-2 rounded-[20px] bg-[#4452a3] bookDemo"
          >
            <p className="text-xl font-medium text-white font-customFont">Book a demo today!</p>
            <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
              <svg
                className="absolute arrowNav top-2"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
