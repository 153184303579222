import Footer from '../Footer';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import reportingImage from '../img/ReportingImage.png';
import { Link } from 'react-router-dom';

export default function Reporting() {
  return (
    <>
      <MobileNavigation />
      <main className="w-full h-full px-[200px] py-10 flex-col justify-start items-center gap-[75px] inline-flex">
        <Header />

        <section className="inline-flex items-center self-stretch justify-between max-[1516px]:justify-center max-[1516px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-6 max-[1516px]:items-center">
            <h2 className="text-[#4452A3] text-[25px] font-light font-customFont">REPORTING</h2>
            <h1 className="text-black text-[45px] font-medium font-customFont">
              Build reports <span className="text-[#C0F] font-semibold">your way</span>.
            </h1>
            <p className="text-black text-[25px] font-light font-customFont">
              View, reconcile and analyze data from transactions and settlements.
            </p>
            <Link
              to="/bookDemo"
              className="flex items-center justify-center gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] headerDemo"
            >
              <p className="text-xl font-medium text-white font-customFont">Book a Demo</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[643.989px] h-[422.405px] justify-self-center">
            <img
              src={reportingImage}
              alt="reporting view screenshot with labels 'Pending Settlement', 'Today's Transactions', 'Last 30 Days', 'August', and 'Settled' "
            />
          </figure>
        </section>
        <section className="flex self-stretch justify-between max-[1516px]:justify-center max-[1516px]:gap-4 items-center max-[1516px]:grid cards">
          <article
            className="w-[300px] h-[199px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[72.866px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="73"
                height="75"
                viewBox="0 0 73 75"
                fill="none"
              >
                <path
                  d="M67.5748 67.6699C66.9388 67.6699 66.4226 67.1537 66.4226 66.5177V21.6547C66.4226 19.1827 64.4115 17.1732 61.941 17.1732H23.3771C22.7411 17.1732 22.2249 16.657 22.2249 16.0209C22.2249 15.3849 22.7411 14.8687 23.3771 14.8687H61.941C65.6836 14.8687 68.7271 17.9137 68.7271 21.6547V66.5177C68.7271 67.1537 68.2109 67.6699 67.5748 67.6699Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M67.3966 74.9969H32.1958C29.1799 74.9969 26.7264 72.5433 26.7264 69.5275V20.5225C26.7264 18.6743 25.2238 17.1717 23.3756 17.1717C21.5273 17.1717 20.0248 18.6743 20.0248 20.5225V41.3969C20.0248 44.4926 17.5052 47.0122 14.4094 47.0122C12.9514 47.0122 11.5902 46.4515 10.5732 45.4329C9.51463 44.3713 8.93388 42.9317 8.93849 41.3815V12.0311H1.15226C0.516214 12.0311 0 11.5149 0 10.8788V5.65677C0 2.53798 2.53652 0.00146484 5.65531 0.00146484C8.7741 0.00146484 11.243 2.50725 11.243 5.58917V41.3861C11.2399 42.3233 11.5826 43.1821 12.2063 43.8074C12.7871 44.3897 13.569 44.7108 14.4094 44.7108C16.2346 44.7108 17.7203 43.2251 17.7203 41.3999V20.5256C17.7203 17.4068 20.2568 14.8703 23.3756 14.8703C26.4944 14.8703 29.0309 17.4068 29.0309 20.5256V69.5305C29.0309 71.2758 30.4505 72.6954 32.1958 72.6954C33.9411 72.6954 35.3606 71.2758 35.3606 69.5305V66.5208C35.3606 65.8848 35.8769 65.3685 36.5129 65.3685H71.7137C72.3498 65.3685 72.866 65.8848 72.866 66.5208V69.5305C72.866 72.5464 70.4125 74.9999 67.3966 74.9999V74.9969ZM36.6542 72.6923H67.3966C69.1419 72.6923 70.5615 71.2727 70.5615 69.5275V67.67H37.6652V69.5275C37.6652 70.7058 37.2903 71.7982 36.6542 72.6923ZM2.30606 9.72656H8.94003V5.58763C8.94003 3.77781 7.46821 2.30445 5.65685 2.30445C3.84549 2.30445 2.30606 3.807 2.30606 5.65523V9.72502V9.72656Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M27.8802 47.0125H14.4111C13.775 47.0125 13.2588 46.4963 13.2588 45.8603C13.2588 45.2242 13.775 44.708 14.4111 44.708H27.8802C28.5163 44.708 29.0325 45.2242 29.0325 45.8603C29.0325 46.4963 28.5163 47.0125 27.8802 47.0125Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M49.6303 17.1733C48.9943 17.1733 48.478 16.6571 48.478 16.0211V5.25893C48.478 3.6304 47.1537 2.30452 45.5236 2.30452H5.6569C5.02085 2.30452 4.50464 1.78831 4.50464 1.15226C4.50464 0.516214 5.02085 0 5.6569 0H45.5236C48.4227 0 50.7826 2.3583 50.7826 5.25893V16.0195C50.7826 16.6556 50.2664 17.1718 49.6303 17.1718V17.1733Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M34.5863 60.2511C33.9503 60.2511 33.4341 59.7349 33.4341 59.0989V56.8681C33.4341 56.232 33.9503 55.7158 34.5863 55.7158C35.2224 55.7158 35.7386 56.232 35.7386 56.8681V59.0989C35.7386 59.7349 35.2224 60.2511 34.5863 60.2511Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M39.0478 60.2509C38.4117 60.2509 37.8955 59.7347 37.8955 59.0987V54.6371C37.8955 54.0011 38.4117 53.4849 39.0478 53.4849C39.6838 53.4849 40.2 54.0011 40.2 54.6371V59.0987C40.2 59.7347 39.6838 60.2509 39.0478 60.2509Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M43.5094 60.251C42.8734 60.251 42.3572 59.7348 42.3572 59.0988V53.1039C42.3572 52.4679 42.8734 51.9517 43.5094 51.9517C44.1455 51.9517 44.6617 52.4679 44.6617 53.1039V59.0988C44.6617 59.7348 44.1455 60.251 43.5094 60.251Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M47.9711 60.2511C47.3351 60.2511 46.8188 59.7349 46.8188 59.0988V50.1757C46.8188 49.5397 47.3351 49.0234 47.9711 49.0234C48.6072 49.0234 49.1234 49.5397 49.1234 50.1757V59.0988C49.1234 59.7349 48.6072 60.2511 47.9711 60.2511Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.4325 60.251C51.7965 60.251 51.2803 59.7348 51.2803 59.0988V53.1039C51.2803 52.4679 51.7965 51.9517 52.4325 51.9517C53.0686 51.9517 53.5848 52.4679 53.5848 53.1039V59.0988C53.5848 59.7348 53.0686 60.251 52.4325 60.251Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M56.8957 60.251C56.2596 60.251 55.7434 59.7348 55.7434 59.0987V48.3627C55.7434 47.7267 56.2596 47.2104 56.8957 47.2104C57.5317 47.2104 58.0479 47.7267 58.0479 48.3627V59.0987C58.0479 59.7348 57.5317 60.251 56.8957 60.251Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.3571 60.251C60.721 60.251 60.2048 59.7347 60.2048 59.0987V43.6215C60.2048 42.9855 60.721 42.4692 61.3571 42.4692C61.9931 42.4692 62.5094 42.9855 62.5094 43.6215V59.0987C62.5094 59.7347 61.9931 60.251 61.3571 60.251Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M47.971 37.6634H34.5863C33.9503 37.6634 33.4341 37.1472 33.4341 36.5111C33.4341 35.8751 33.9503 35.3589 34.5863 35.3589H47.971C48.6071 35.3589 49.1233 35.8751 49.1233 36.5111C49.1233 37.1472 48.6071 37.6634 47.971 37.6634Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.3572 31.3617H34.5863C33.9503 31.3617 33.4341 30.8454 33.4341 30.2094C33.4341 29.5733 33.9503 29.0571 34.5863 29.0571H61.3572C61.9933 29.0571 62.5095 29.5733 62.5095 30.2094C62.5095 30.8454 61.9933 31.3617 61.3572 31.3617Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.6692 25.0594H34.5863C33.9503 25.0594 33.4341 24.5432 33.4341 23.9071C33.4341 23.2711 33.9503 22.7549 34.5863 22.7549H52.6692C53.3052 22.7549 53.8215 23.2711 53.8215 23.9071C53.8215 24.5432 53.3052 25.0594 52.6692 25.0594Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M61.3572 25.0594H57.5102C56.8741 25.0594 56.3579 24.5432 56.3579 23.9071C56.3579 23.2711 56.8741 22.7549 57.5102 22.7549H61.3572C61.9932 22.7549 62.5094 23.2711 62.5094 23.9071C62.5094 24.5432 61.9932 25.0594 61.3572 25.0594Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M42.9549 10.1229H25.0549C24.4188 10.1229 23.9026 9.60667 23.9026 8.97062C23.9026 8.33457 24.4188 7.81836 25.0549 7.81836H42.9549C43.5909 7.81836 44.1071 8.33457 44.1071 8.97062C44.1071 9.60667 43.5909 10.1229 42.9549 10.1229Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.8036 10.1229H16.184C15.548 10.1229 15.0317 9.60667 15.0317 8.97062C15.0317 8.33457 15.548 7.81836 16.184 7.81836H19.8036C20.4397 7.81836 20.9559 8.33457 20.9559 8.97062C20.9559 9.60667 20.4397 10.1229 19.8036 10.1229Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Create quick reports from saved custom filters
            </p>
          </article>
          <article
            className="w-[300px] h-[199px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[69.107px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="75"
                viewBox="0 0 70 75"
                fill="none"
              >
                <path
                  d="M42.9758 65.3446C33.7081 65.3446 26.1677 57.8042 26.1677 48.5365C26.1677 39.2688 33.7081 31.7285 42.9758 31.7285C52.2435 31.7285 59.7838 39.2688 59.7838 48.5365C59.7838 57.8042 52.2435 65.3446 42.9758 65.3446ZM42.9758 34.021C34.9711 34.021 28.4587 40.5334 28.4587 48.5381C28.4587 56.5427 34.9711 63.0551 42.9758 63.0551C50.9804 63.0551 57.4928 56.5427 57.4928 48.5381C57.4928 40.5334 50.9804 34.021 42.9758 34.021Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M53.1797 53.9934C53.0316 53.9934 52.8804 53.9644 52.7337 53.9033C52.1503 53.6574 51.8785 52.9854 52.1244 52.4019C52.6421 51.1786 52.9048 49.8788 52.9048 48.5378C52.9048 44.8921 50.9116 41.5458 47.7043 39.8047C47.1484 39.5023 46.9422 38.8074 47.2446 38.2514C47.547 37.6955 48.2419 37.4893 48.7979 37.7917C52.7444 39.9345 55.1958 44.0521 55.1958 48.5378C55.1958 50.1873 54.872 51.7879 54.2351 53.2954C54.0503 53.7322 53.6257 53.9949 53.1797 53.9949V53.9934Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M44.0053 38.6547C43.9701 38.6547 43.935 38.6532 43.8984 38.6501C43.5929 38.6226 43.2829 38.6074 42.9743 38.6074C42.342 38.6074 41.8289 38.0942 41.8289 37.4619C41.8289 36.8296 42.342 36.3164 42.9743 36.3164C43.3516 36.3164 43.7334 36.3332 44.1076 36.3683C44.7368 36.4264 45.2011 36.9838 45.1431 37.6146C45.0881 38.2087 44.5887 38.6547 44.0037 38.6547H44.0053Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M68.2941 75.0001C68.0008 75.0001 67.7076 74.8886 67.4846 74.6641L53.2409 60.4205C52.7934 59.973 52.7934 59.2475 53.2409 58.8C53.6884 58.3525 54.4139 58.3525 54.8614 58.8L69.1051 73.0437C69.5526 73.4912 69.5526 74.2166 69.1051 74.6641C68.8821 74.8871 68.5888 75.0001 68.2956 75.0001H68.2941Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M48.6573 73.0525H1.47873C0.846428 73.0525 0.333252 72.5393 0.333252 71.907V6.3198C0.333252 5.68749 0.846428 5.17432 1.47873 5.17432H48.6573C49.2896 5.17432 49.8027 5.68749 49.8027 6.3198V28.7789C49.8027 29.4112 49.2896 29.9243 48.6573 29.9243C48.025 29.9243 47.5118 29.4112 47.5118 28.7789V7.46528H2.62421V70.7615H47.5118V68.9929C47.5118 68.3606 48.025 67.8474 48.6573 67.8474C49.2896 67.8474 49.8027 68.3606 49.8027 68.9929V71.907C49.8027 72.5393 49.2896 73.0525 48.6573 73.0525Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.0501 32.9776C53.4178 32.9776 52.9046 32.4645 52.9046 31.8322V2.29096H6.87156C6.23925 2.29096 5.72607 1.77779 5.72607 1.14548C5.72607 0.513176 6.23925 0 6.87156 0H54.0501C54.6824 0 55.1956 0.513176 55.1956 1.14548V31.8322C55.1956 32.4645 54.6824 32.9776 54.0501 32.9776Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.2245 18.3535H10.021C9.38866 18.3535 8.87549 17.8403 8.87549 17.208C8.87549 16.5757 9.38866 16.0625 10.021 16.0625H19.2245C19.8568 16.0625 20.37 16.5757 20.37 17.208C20.37 17.8403 19.8568 18.3535 19.2245 18.3535Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M41.5676 27.4121H10.0195C9.3872 27.4121 8.87402 26.8989 8.87402 26.2666C8.87402 25.6343 9.3872 25.1211 10.0195 25.1211H41.5676C42.1999 25.1211 42.7131 25.6343 42.7131 26.2666C42.7131 26.8989 42.1999 27.4121 41.5676 27.4121Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M27.3132 33.5151H10.0195C9.3872 33.5151 8.87402 33.0019 8.87402 32.3696C8.87402 31.7373 9.3872 31.2241 10.0195 31.2241H27.3132C27.9455 31.2241 28.4587 31.7373 28.4587 32.3696C28.4587 33.0019 27.9455 33.5151 27.3132 33.5151Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M22.3815 39.6201H10.0195C9.3872 39.6201 8.87402 39.1069 8.87402 38.4746C8.87402 37.8423 9.3872 37.3291 10.0195 37.3291H22.3815C23.0138 37.3291 23.527 37.8423 23.527 38.4746C23.527 39.1069 23.0138 39.6201 22.3815 39.6201Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M22.3815 45.7231H10.0195C9.3872 45.7231 8.87402 45.2099 8.87402 44.5776C8.87402 43.9453 9.3872 43.4321 10.0195 43.4321H22.3815C23.0138 43.4321 23.527 43.9453 23.527 44.5776C23.527 45.2099 23.0138 45.7231 22.3815 45.7231Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M22.3815 51.8276H10.0195C9.3872 51.8276 8.87402 51.3144 8.87402 50.6821C8.87402 50.0498 9.3872 49.5366 10.0195 49.5366H22.3815C23.0138 49.5366 23.527 50.0498 23.527 50.6821C23.527 51.3144 23.0138 51.8276 22.3815 51.8276Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.8691 57.9306H10.021C9.38866 57.9306 8.87549 57.4174 8.87549 56.7851C8.87549 56.1528 9.38866 55.6396 10.021 55.6396H23.8691C24.5014 55.6396 25.0146 56.1528 25.0146 56.7851C25.0146 57.4174 24.5014 57.9306 23.8691 57.9306Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M26.7053 64.0356H10.0195C9.3872 64.0356 8.87402 63.5224 8.87402 62.8901C8.87402 62.2578 9.3872 61.7446 10.0195 61.7446H26.7053C27.3377 61.7446 27.8508 62.2578 27.8508 62.8901C27.8508 63.5224 27.3377 64.0356 26.7053 64.0356Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Advanced search features
            </p>
          </article>
          <article
            className="w-[300px] h-[199px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[69.833px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="73"
                height="77"
                viewBox="0 0 73 77"
                fill="none"
              >
                <path
                  d="M53.4783 64.5083V69.6807H19.688V64.5083"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M36.5834 61.9221L24.1936 45.1117H30.9517V27.0083H42.2151V45.1117H48.9731L36.5834 61.9221Z"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.9519 21.8359H42.2153"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.9519 16.6641H42.2153"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M71.5 76V20.3966L54.6049 1H3.91943C2.68046 1 1.66675 2.16379 1.66675 3.58621V76"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="round"
                />
                <path
                  d="M71.4999 20.3967H55.7311C55.1116 20.3967 54.6047 19.8148 54.6047 19.1036V13.9312"
                  stroke="#1C7ABF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Export report data to a PDF or CSV
            </p>
          </article>
          <article
            className="w-[300px] h-[199px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[73.773px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="74"
                height="76"
                viewBox="0 0 74 76"
                fill="none"
              >
                <path
                  d="M10.3571 75.0918C10.0228 75.0918 9.69579 75.0772 9.36872 75.0481C4.02665 74.6193 0 70.84 0 66.2684C0 65.6651 0.486965 65.1781 1.09022 65.1781C1.69348 65.1781 2.18044 65.6651 2.18044 66.2684C2.18044 69.6989 5.34944 72.5406 9.54315 72.875C9.81207 72.8968 10.0809 72.9114 10.3571 72.9114C14.8706 72.9114 18.5337 69.9315 18.5337 66.2684V62.7723C18.5337 62.1691 19.0207 61.6821 19.624 61.6821C20.2272 61.6821 20.7142 62.1691 20.7142 62.7723V66.2684C20.7142 71.138 16.0698 75.0918 10.3571 75.0918Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.1329 75.0918H10.3642C9.76098 75.0918 9.27402 74.6048 9.27402 74.0016C9.27402 73.3983 9.76098 72.9114 10.3642 72.9114C14.8777 72.9114 18.5409 69.9315 18.5409 66.2684V62.7723C18.5409 62.1691 19.0279 61.6821 19.6311 61.6821H63.3998C64.003 61.6821 64.49 62.1691 64.49 62.7723V66.2684C64.49 71.138 59.8457 75.0918 54.1329 75.0918ZM17.1746 72.9114H54.1329C58.6464 72.9114 62.3096 69.9315 62.3096 66.2684V63.8626H20.7213V66.2684C20.7213 68.914 19.3477 71.2906 17.1746 72.9114Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M1.09022 67.4164C0.486965 67.4164 0 66.9295 0 66.3262V1.18202C0 0.578762 0.486965 0.0917969 1.09022 0.0917969H56.2773C56.8805 0.0917969 57.3675 0.578762 57.3675 1.18202V62.7722C57.3675 63.3754 56.8805 63.8624 56.2773 63.8624C55.674 63.8624 55.1871 63.3754 55.1871 62.7722V2.27224H2.18044V66.3262C2.18044 66.9295 1.69348 67.4164 1.09022 67.4164Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M72.6811 66.4282C72.4049 66.4282 72.1214 66.3192 71.9107 66.1084L33.6949 27.8925C33.2661 27.4637 33.2661 26.7732 33.6949 26.3516C34.1238 25.9301 34.8141 25.9228 35.2357 26.3516L73.4516 64.5675C73.8804 64.9963 73.8804 65.6869 73.4516 66.1084C73.2408 66.3192 72.9573 66.4282 72.6811 66.4282Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M32.4957 32.544C32.1468 32.544 31.8125 32.3769 31.609 32.0862L29.036 28.4449L24.5808 28.5029C24.1883 28.5319 23.7812 28.2777 23.5922 27.907C23.4032 27.5363 23.4397 27.0929 23.6868 26.7586L26.3541 23.1827L24.9223 18.9599C24.7915 18.5674 24.8933 18.1313 25.1841 17.8406C25.4748 17.5499 25.9107 17.4482 26.3032 17.579L30.526 19.0108L34.1021 16.3433C34.4364 16.0962 34.8797 16.0599 35.2503 16.2489C35.621 16.4379 35.8536 16.823 35.8464 17.2373L35.7882 21.6927L39.4295 24.2657C39.7711 24.5055 39.9384 24.9198 39.8802 25.3268C39.8148 25.7338 39.5241 26.0754 39.1243 26.1989L34.8651 27.5218L33.5424 31.7808C33.4189 32.1733 33.0844 32.4713 32.6701 32.5367C32.612 32.544 32.5538 32.5513 32.4957 32.5513V32.544ZM29.5884 26.2571C29.9446 26.2571 30.2718 26.4315 30.4753 26.7149L32.1034 29.0191L32.9391 26.3224C33.0481 25.9808 33.3171 25.7119 33.6587 25.6029L36.3551 24.7671L34.0512 23.1391C33.7604 22.9283 33.5859 22.594 33.5932 22.2378L33.6296 19.4177L31.3693 21.104C31.0785 21.3147 30.7078 21.3801 30.3662 21.2638L27.6915 20.3553L28.6001 23.0301C28.7164 23.3717 28.6582 23.7423 28.4402 24.033L26.7539 26.2935L29.5739 26.2571H29.5884Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.6577 17.4048C23.3816 17.4048 23.0981 17.2956 22.8873 17.0848L19.2241 13.4217C18.7953 12.9928 18.7953 12.3025 19.2241 11.8809C19.6529 11.4594 20.3435 11.4521 20.765 11.8809L24.4282 15.5441C24.857 15.9729 24.857 16.6633 24.4282 17.0848C24.2174 17.2956 23.9339 17.4048 23.6577 17.4048Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.6992 24.5486H15.5171C14.9138 24.5486 14.4269 24.0616 14.4269 23.4584C14.4269 22.8551 14.9138 22.3682 15.5171 22.3682H20.6992C21.3024 22.3682 21.7894 22.8551 21.7894 23.4584C21.7894 24.0616 21.3024 24.5486 20.6992 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M46.0872 24.5486H40.9049C40.3017 24.5486 39.8147 24.0616 39.8147 23.4584C39.8147 22.8551 40.3017 22.3682 40.9049 22.3682H46.0872C46.6904 22.3682 47.1774 22.8551 47.1774 23.4584C47.1774 24.0616 46.6904 24.5486 46.0872 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.9946 35.3572C19.7184 35.3572 19.4349 35.2482 19.2241 35.0374C18.7953 34.6086 18.7953 33.9181 19.2241 33.4965L22.8873 29.8333C23.3161 29.4045 24.0067 29.4045 24.4282 29.8333C24.857 30.2622 24.857 30.9527 24.4282 31.3743L20.765 35.0374C20.5543 35.2482 20.2708 35.3572 19.9946 35.3572Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M37.9469 17.4045C37.6707 17.4045 37.3873 17.2954 37.1766 17.0846C36.7477 16.6558 36.7477 15.9654 37.1766 15.5439L40.8396 11.8807C41.2684 11.4519 41.9589 11.4519 42.3805 11.8807C42.802 12.3095 42.8093 12.9999 42.3805 13.4214L38.7173 17.0846C38.5065 17.2954 38.223 17.4045 37.9469 17.4045Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 39.8337C30.199 39.8337 29.712 39.3468 29.712 38.7435V33.5614C29.712 32.9582 30.199 32.4712 30.8022 32.4712C31.4055 32.4712 31.8924 32.9582 31.8924 33.5614V38.7435C31.8924 39.3468 31.4055 39.8337 30.8022 39.8337Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 14.4467C30.199 14.4467 29.712 13.9597 29.712 13.3565V8.1742C29.712 7.57095 30.199 7.08398 30.8022 7.08398C31.4055 7.08398 31.8924 7.57095 31.8924 8.1742V13.3565C31.8924 13.9597 31.4055 14.4467 30.8022 14.4467Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Automatially calculate running totals
            </p>
          </article>
        </section>
        <section className="flex w-full max-md:w-auto px-[122px] py-[100px] flex-col items-start gap-[50px] bg-[url('./img/softwarePartners.png')] bg-cover bg-bottom md:rounded-3xl">
          <h1 className="flex flex-col items-start justify-center text-white text-[55px] font-medium font-customFont">
            How does Reporting work?
          </h1>
          <h2 className="text-white text-[35px] font-normal font-customFont">
            iQ Pro+ simplifies payment reconciliation.
          </h2>
          <div className="flex flex-col justify-center items-start gap-[50px] self-stretch">
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Only view the information you need using custom filters.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Export transaction and settlement data seamlesssly across platforms.
                </p>
              </div>
            </article>
          </div>
        </section>
        <section className="h-[157px] max-md:h-auto flex-col justify-start items-center gap-[25px] inline-flex">
          <h1 className="self-stretch text-center text-black text-[45px] font-medium font-customFont">
            Ready to get started?
          </h1>
          <Link
            to="/bookDemo"
            className="flex items-start gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] bookDemo"
          >
            <p className="text-xl font-medium text-center text-white font-customFont">
              Book a demo today!
            </p>
            <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
              <svg
                className="absolute arrowNav top-2"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
