import logo from './img/iQPro+ Logo.png';
import { MdOutlineMenu } from 'react-icons/md';
import { useState } from 'react';
import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from '@gsap/business';
import DrawSVGPlugin from '@gsap/business/DrawSVGPlugin';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';

gsap.registerPlugin(useGSAP);

gsap.registerPlugin(DrawSVGPlugin);

export default function MobileNavigation() {
  const container = useRef();
  const [click, setClick] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [childrenDrawerTwo, setChildrenDrawerTwo] = useState(false);
  const [childrenDrawerThree, setChildrenDrawerThree] = useState(false);
  const arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="31" viewBox="0 0 37 31" fill="none">
      <path
        d="M36.1528 15.4595C36.1528 15.7655 36.0313 16.059 35.8149 16.2754C35.5985 16.4918 35.305 16.6133 34.999 16.6133H3.93937L15.8144 28.4903C15.9277 28.5959 16.0187 28.7233 16.0817 28.8648C16.1448 29.0064 16.1787 29.1592 16.1814 29.3141C16.1842 29.469 16.1557 29.6229 16.0976 29.7666C16.0396 29.9102 15.9532 30.0408 15.8437 30.1503C15.7341 30.2599 15.6036 30.3463 15.4599 30.4043C15.3162 30.4623 15.1623 30.4908 15.0074 30.4881C14.8525 30.4854 14.6997 30.4515 14.5582 30.3884C14.4166 30.3253 14.2892 30.2344 14.1836 30.121L0.337446 16.2749C0.121368 16.0585 0 15.7653 0 15.4595C0 15.1537 0.121368 14.8605 0.337446 14.6441L14.1836 0.797961C14.4023 0.594145 14.6916 0.483187 14.9906 0.488461C15.2895 0.493735 15.5747 0.61483 15.7861 0.826235C15.9975 1.03764 16.1186 1.32285 16.1239 1.62177C16.1291 1.9207 16.0182 2.21 15.8144 2.42873L3.93937 14.3057H34.999C35.305 14.3057 35.5985 14.4272 35.8149 14.6436C36.0313 14.86 36.1528 15.1535 36.1528 15.4595Z"
        fill="#000066"
      />
    </svg>
  );
  const close = (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
      <path
        d="M29.6328 28.4903C29.7461 28.5959 29.837 28.7233 29.9001 28.8648C29.9632 29.0064 29.9971 29.1592 29.9998 29.3141C30.0026 29.469 29.9741 29.6229 29.916 29.7666C29.858 29.9102 29.7716 30.0408 29.662 30.1503C29.5525 30.2599 29.422 30.3463 29.2783 30.4043C29.1346 30.4623 28.9807 30.4908 28.8258 30.4881C28.6709 30.4854 28.5181 30.4515 28.3765 30.3884C28.235 30.3253 28.1076 30.2344 28.002 30.121L14.9712 17.0922L1.94045 30.121C1.72172 30.3249 1.43242 30.4358 1.13349 30.4305C0.834566 30.4253 0.549358 30.3042 0.337954 30.0928C0.126549 29.8814 0.00545394 29.5962 0.000179765 29.2972C-0.00509441 28.9983 0.105864 28.709 0.309679 28.4903L13.3385 15.4595L0.309679 2.42873C0.105864 2.21 -0.00509441 1.9207 0.000179765 1.62177C0.00545394 1.32285 0.126549 1.03764 0.337954 0.826235C0.549358 0.61483 0.834566 0.493735 1.13349 0.488461C1.43242 0.483187 1.72172 0.594145 1.94045 0.797961L14.9712 13.8268L28.002 0.797961C28.2207 0.594145 28.51 0.483187 28.8089 0.488461C29.1079 0.493735 29.3931 0.61483 29.6045 0.826235C29.8159 1.03764 29.937 1.32285 29.9423 1.62177C29.9475 1.9207 29.8366 2.21 29.6328 2.42873L16.6039 15.4595L29.6328 28.4903Z"
        fill="#000066"
      />
    </svg>
  );
  const showChildrenDrawer = () => {
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };
  const showChildrenDrawerTwo = () => {
    setChildrenDrawerTwo(true);
  };
  const onChildrenDrawerCloseTwo = () => {
    setChildrenDrawerTwo(false);
  };
  const showChildrenDrawerThree = () => {
    setChildrenDrawerThree(true);
  };
  const onChildrenDrawerCloseThree = () => {
    setChildrenDrawerThree(false);
  };

  const Hamburger = (
    <MdOutlineMenu
      className="self-center HamburgerMenu justify-self-center"
      size="30px"
      color="black"
      onClick={() => {
        setClick(!click);
      }}
    />
  );
  useGSAP(
    () => {
      // let arrowAnimationMobile = gsap.to('#arrowTopNav', {
      //   rotation: 360,
      //   x: 10,
      //   paused: true
      // });
      // let buttonTopMobile = document.getElementById('navTopButton');
      // buttonTopMobile.addEventListener('mouseenter', () => arrowAnimationMobile.play());
      // buttonTopMobile.addEventListener('mouseleave', () => arrowAnimationMobile.reverse());
    },
    { scope: container }
  );
  return (
    <header
      ref={container}
      className="w-full h-[70.08px] pl-5 pr-12 bg-white shadow justify-between items-center inline-flex min-[1270px]:hidden"
    >
      <a
        className={`h-[103.65px] p-[26px] flex-col justify-start items-start inline-flex`}
        href="/"
      >
        <img className="w-[144.73px] h-[51.65px] flex-shrink-0" src={logo} alt="iQ Pro+ Logo" />
      </a>
      <Drawer
        width={393}
        onClose={() => {
          setClick(!click);
        }}
        open={click}
        placement="left"
        closeIcon={close}
        extra={
          <Link
            to="/"
            className="w-[150px] h-[78.98px] p-[19.81px] flex-col justify-between items-start inline-flex"
          >
            <img className="w-[144.73px] h-[51.65px]" src={logo} alt="iQ Pro+ Logo" />
          </Link>
        }
      >
        <section className="h-[450px] w-full flex-col justify-start items-start gap-[25px] inline-flex">
          <button
            onClick={showChildrenDrawer}
            className="h-[59px] w-full py-5 justify-between items-center inline-flex"
          >
            <p className="text-[#000066] text-[25.68px] font-normal font-customFont">Features</p>
            <svg
              className="arrowNav"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                fill="#000066"
              />
            </svg>
          </button>
          <Drawer
            width={393}
            onClose={onChildrenDrawerClose}
            closeIcon={arrow}
            open={childrenDrawer}
            placement="left"
            extra={
              <Link
                to="/"
                className="w-[150px] h-[78.98px] p-[19.81px] flex-col justify-between items-start inline-flex"
              >
                <img className="w-[144.73px] h-[51.65px]" src={logo} alt="iQ Pro+ Logo" />
              </Link>
            }
          >
            <nav className="h-[685.02px] w-full flex-col justify-start items-start gap-[25px] inline-flex">
              <Link
                to="/features/terminal"
                className="inline-flex items-center justify-between w-full py-2 "
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Virtual Terminal
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/simplePay"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Simple Pay
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/mobileApp"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Mobile App
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/customers"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Customers
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/invoicing"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Invoicing
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/recurringBilling"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Recurring Billing
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/reporting"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Reporting
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
              <Link
                to="/features/physicalTerminals"
                className="inline-flex items-center justify-between w-full py-2"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Physical Terminals
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </Link>
            </nav>
          </Drawer>
          <button
            onClick={showChildrenDrawerTwo}
            className="h-[59px] w-full py-5 justify-between items-center inline-flex"
          >
            <p className="text-[#000066] text-[25.68px] font-normal font-customFont">Resources</p>
            <svg
              className="arrowNav"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                fill="#000066"
              />
            </svg>
          </button>
          <Drawer
            width={393}
            onClose={onChildrenDrawerCloseTwo}
            closeIcon={arrow}
            open={childrenDrawerTwo}
            placement="left"
            extra={
              <Link
                to="/"
                className="w-[150px] h-[78.98px] p-[19.81px] flex-col justify-between items-start inline-flex"
              >
                <img className="w-[144.73px] h-[51.65px]" src={logo} alt="iQ Pro+ Logo" />
              </Link>
            }
          >
            <nav className="grid gap-[25px]">
              <a
                href="https://developer.basyspro.com/"
                className="h-[59px] inline-flex items-center justify-between w-full py-5"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Developer Docs
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </a>
              <a
                href="https://basyspro.com/equipment/qrg/qrg-iqpro/"
                className="h-[59px] inline-flex items-center justify-between w-full py-5"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Quick Reference Guide
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </a>
            </nav>
          </Drawer>
          <Link
            to="/support"
            className="h-[59px] w-full py-5 justify-between items-center inline-flex"
          >
            <p className="text-[#000066] text-[25.68px] font-normal font-customFont">Support</p>
            <svg
              className="arrowNav"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                fill="#000066"
              />
            </svg>
          </Link>
          <button
            onClick={showChildrenDrawerThree}
            className="h-[59px] w-full py-5 justify-between items-center inline-flex"
          >
            <p className="text-[#000066] text-[25.68px] font-normal font-customFont">Login</p>
            <svg
              className="arrowNav"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
            >
              <path
                d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                fill="#000066"
              />
            </svg>
          </button>
          <Drawer
            width={393}
            onClose={onChildrenDrawerCloseThree}
            closeIcon={arrow}
            open={childrenDrawerThree}
            placement="left"
            extra={
              <Link
                to="/"
                className="w-[150px] h-[78.98px] p-[19.81px] flex-col justify-between items-start inline-flex"
              >
                <img className="w-[144.73px] h-[51.65px]" src={logo} alt="iQ Pro+ Logo" />
              </Link>
            }
          >
            <nav className="grid gap-[25px]">
              <a
                href="https://basyspro.net/Default.aspx"
                className="h-[59px] inline-flex items-center justify-between w-full py-5"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">
                  Basys IQ
                </p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </a>
              <a
                href="https://app.basysiqpro.com/auth"
                className="h-[59px] inline-flex items-center justify-between w-full py-5"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">iQ Pro</p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </a>
              <a
                href="https://app.iqpro.com"
                className="h-[59px] inline-flex items-center justify-between w-full py-5"
                target="_blank"
                rel="noreferrer"
              >
                <p className="text-[#000066] text-[25.68px] font-normal font-customFont">iQ Pro+</p>
                <svg
                  className="arrowNav"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M16.9998 0.780939V13.3016C16.9998 13.4932 16.9237 13.6769 16.7882 13.8124C16.6528 13.9478 16.469 14.0239 16.2774 14.0239C16.0859 14.0239 15.9021 13.9478 15.7667 13.8124C15.6312 13.6769 15.5551 13.4932 15.5551 13.3016V2.5242L1.37787 16.7014C1.24094 16.829 1.05983 16.8985 0.872688 16.8952C0.685552 16.8919 0.507002 16.8161 0.374656 16.6837C0.24231 16.5514 0.1665 16.3728 0.163198 16.1857C0.159897 15.9986 0.22936 15.8174 0.356955 15.6805L14.5342 1.50328H3.75679C3.56521 1.50328 3.38148 1.42718 3.24602 1.29171C3.11055 1.15625 3.03445 0.972517 3.03445 0.780939C3.03445 0.589361 3.11055 0.40563 3.24602 0.270164C3.38148 0.134698 3.56521 0.0585938 3.75679 0.0585938H16.2774C16.469 0.0585938 16.6528 0.134698 16.7882 0.270164C16.9237 0.40563 16.9998 0.589361 16.9998 0.780939Z"
                    fill="#000066"
                  />
                </svg>
              </a>
            </nav>
          </Drawer>
        </section>
        <Link
          to="/bookDemo"
          className="h-[68px] w-full px-4 bg-[#4452a3] rounded-[999px] justify-center items-center gap-4 inline-flex"
        >
          <div className="h-[16.25px] justify-start items-center gap-3 inline-flex">
            <p className="text-xl font-medium text-white font-customFont">Contact Sales</p>
            <svg
              className="arrowNav"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M17.125 1.57217V13.6565C17.125 13.8414 17.0515 14.0188 16.9208 14.1495C16.7901 14.2802 16.6127 14.3537 16.4278 14.3537C16.2429 14.3537 16.0656 14.2802 15.9348 14.1495C15.8041 14.0188 15.7307 13.8414 15.7307 13.6565V3.25469L2.04745 16.9379C1.91529 17.061 1.74049 17.1281 1.55988 17.1249C1.37926 17.1217 1.20693 17.0485 1.0792 16.9208C0.951463 16.7931 0.878295 16.6207 0.875109 16.4401C0.871922 16.2595 0.938965 16.0847 1.06211 15.9525L14.7453 2.26935H4.34348C4.15858 2.26935 3.98125 2.1959 3.8505 2.06515C3.71976 1.9344 3.6463 1.75708 3.6463 1.57217C3.6463 1.38727 3.71976 1.20994 3.8505 1.0792C3.98125 0.948452 4.15858 0.875 4.34348 0.875H16.4278C16.6127 0.875 16.7901 0.948452 16.9208 1.0792C17.0515 1.20994 17.125 1.38727 17.125 1.57217Z"
                fill="white"
              />
            </svg>
          </div>
        </Link>
      </Drawer>
      <div className="w-[414.13px] max-[768px]:w-auto justify-end items-center gap-6 flex-shrink-0 inline-flex">
        <Link
          className="min-[768px]:inline-flex px-4 py-2 bg-[#4452a3] rounded-[999px] justify-center items-center gap-4 hidden"
          to="/bookDemo"
        >
          <p className="text-xl font-medium text-white font-customFont">Contact Sales</p>
          <svg
            className="arrowNav"
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M17 1.4003V13.4846C17 13.6695 16.9265 13.8469 16.7958 13.9776C16.6651 14.1084 16.4877 14.1818 16.3028 14.1818C16.1179 14.1818 15.9406 14.1084 15.8098 13.9776C15.6791 13.8469 15.6057 13.6695 15.6057 13.4846V3.08281L1.92245 16.766C1.79029 16.8892 1.61549 16.9562 1.43488 16.953C1.25426 16.9498 1.08193 16.8767 0.954197 16.7489C0.826463 16.6212 0.753295 16.4489 0.750109 16.2682C0.746922 16.0876 0.813965 15.9128 0.937114 15.7807L14.6203 2.09747H4.21848C4.03358 2.09747 3.85625 2.02402 3.7255 1.89327C3.59476 1.76253 3.5213 1.5852 3.5213 1.4003C3.5213 1.2154 3.59476 1.03807 3.7255 0.907322C3.85625 0.776577 4.03358 0.703125 4.21848 0.703125H16.3028C16.4877 0.703125 16.6651 0.776577 16.7958 0.907322C16.9265 1.03807 17 1.2154 17 1.4003Z"
              fill="white"
            />
          </svg>
        </Link>
        {Hamburger}
      </div>
    </header>
  );
}
