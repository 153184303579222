import * as React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import '../NavMenu/style.scss';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

const drawerWidth = '60%';

export default function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // mobile drawer
  const drawer = (
    <Grid sx={{ textAlign: 'center' }}>
      <Link to="/onboarding-features">
        <div alt="iQ Pro+ Logo" className="mobile-logo"></div>
      </Link>
      <Divider />
      <List sx={{ width: '90%' }}>
        <ListItem disablePadding>
          <Link to="/onboarding-features">
            <ListItemButton sx={{ width: '100vw' }}>
              <ListItemText>Features</ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/onboarding-training">
            <ListItemButton sx={{ width: '100vw' }}>
              <ListItemText>Training Videos</ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ width: '100vw' }}
            id="dev"
            aria-controls={open ? 'dev-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <ListItemText>Developer Hub</ListItemText>
          </ListItemButton>
        </ListItem>
        <Menu
          id="dev-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'dev'
          }}
        >
          <MenuItem>
            <a target="_blank" rel="noreferrer" href="https://sandbox.developer.basyspro.com/">
              Sandbox Docs
            </a>
          </MenuItem>
          <MenuItem>
            <a target="_blank" rel="noreferrer" href="https://developer.basyspro.com/">
              Prod Docs
            </a>
          </MenuItem>
        </Menu>
        <ListItem disablePadding>
          <Link to="/onboarding-conversion">
            <ListItemButton sx={{ width: '100vw' }}>
              <ListItemText>Support</ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
        <ListItem disablePadding>
          <Link to="/onboarding-sign-in">
            <ListItemButton sx={{ width: '100vw' }}>
              <ListItemText>Sign In</ListItemText>
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Grid>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        height: { xs: '75px', md: '115px' }
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 'none',
          pl: { xs: 3, lg: 10 },
          height: { xs: '75px', md: '115px' }
        }}
      >
        <Grid container alignItems="center">
          <Grid item xs={7} sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{ display: { md: 'none' } }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5} md lg sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link to="/onboarding-features">
              <div alt="iQ Pro+ Logo" className="ob-landing-logo"></div>
            </Link>
          </Grid>
          <Grid
            item
            xs
            md
            lg={5}
            sx={{ display: { xs: 'none', md: 'inherit' } }}
            className="navigation-link-wrapper"
          >
            <ul>
              <li>
                <Link
                  color="inherit"
                  role="aria"
                  to="/onboarding-features"
                  aria-haspopup="true"
                  className="ob-nav-links"
                >
                  Features
                </Link>
              </li>
              <li>
                <Link color="inherit" to="/onboarding-training" className="ob-nav-links">
                  Training Videos
                </Link>
              </li>
              <li
                className="ob-nav-links"
                id="dev"
                aria-controls={open ? 'dev-menu' : undefined}
                onClick={handleClick}
              >
                Developer Hub
              </li>
              <Menu
                id="dev-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'dev'
                }}
              >
                <MenuItem>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://sandbox.developer.basyspro.com/"
                  >
                    Sandbox Docs
                  </a>
                </MenuItem>
                <MenuItem>
                  <a target="_blank" rel="noreferrer" href="https://developer.basyspro.com/">
                    Prod Docs
                  </a>
                </MenuItem>
              </Menu>
              <li>
                <Link color="inherit" to="/onboarding-conversion" className="ob-nav-links">
                  Support
                </Link>
              </li>
              <li>
                <Link color="inherit" to="/onboarding-sign-in" className="ob-nav-links">
                  Sign In
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          disableScrollLock={true}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.object
};
