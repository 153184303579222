import Footer from '../Footer';
import Header from '../Header';
import recurringBillingImage from '../img/RecurringBillingImage.png';
import recurringReporting from '../img/RecurringReporting.png';
import customerRecurring from '../img/CustomerRecurring.png';
import recurringInvoices from '../img/recurringInvoices.png';
import MobileNavigation from '../MobileNavigation';
import { Link } from 'react-router-dom';

export default function RecurringBilling() {
  return (
    <>
      <MobileNavigation />
      <main className="w-full h-full px-[200px] py-10 flex-col justify-start items-center gap-[100px] inline-flex">
        <Header />

        <section className="inline-flex items-center self-stretch justify-between max-[1516px]:justify-center max-[1516px]:grid">
          <article className="inline-flex flex-col items-start self-stretch justify-center gap-6 max-[1516px]:items-center">
            <h2 className="text-[#4452A3] text-[25px] font-light font-customFont">
              RECURRING BILLING
            </h2>
            <h1 className="text-black text-[45px] font-medium font-customFont">
              Save time - bill <span className="font-semibold text-[#C0F]">automatically</span>.
            </h1>
            <p className="text-black text-[25px] font-light font-customFont">
              Recurring payments that simplify the billing process.
            </p>
            <Link
              to="/bookDemo"
              className="flex items-center justify-center gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] headerDemo"
            >
              <p className="text-xl font-medium text-white font-customFont">Book a Demo</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[702.07px] h-[404.83px] relative">
            <img src={recurringBillingImage} alt="recurring billing demo" />
          </figure>
        </section>
        <section className="flex self-stretch justify-between max-[1516px]:justify-center max-[1516px]:gap-4 items-center max-[1516px]:grid cards">
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[96.88px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="97"
                height="76"
                viewBox="0 0 97 76"
                fill="none"
              >
                <path
                  d="M0.00181367 65.8509V12.6827C0.00181367 5.72861 5.63862 0.0917969 12.5927 0.0917969H84.2912C91.2453 0.0917969 96.8803 5.7286 96.8803 12.6809V53.2601C96.8803 60.2141 91.2435 65.8509 84.2894 65.8509H12.5909C5.63681 65.8509 0 72.805 0 65.8509H0.00181367Z"
                  fill="white"
                />
                <path
                  d="M86.1882 61.1443H10.6937C7.39422 61.1443 4.70898 58.4591 4.70898 55.1596V10.7845C4.70898 7.48504 7.39422 4.7998 10.6937 4.7998H67.1669C67.9967 4.7998 68.6707 5.47382 68.6707 6.30367C68.6707 7.13351 67.9967 7.80755 67.1669 7.80755H10.6937C9.0521 7.80755 7.71673 9.14293 7.71673 10.7845V55.1596C7.71673 56.8012 9.0521 58.1366 10.6937 58.1366H86.1882C87.8297 58.1366 89.1651 56.8012 89.1651 55.1596V10.7845C89.1651 9.14293 87.8297 7.80755 86.1882 7.80755H75.6937C74.8638 7.80755 74.1898 7.13351 74.1898 6.30367C74.1898 5.47382 74.8638 4.7998 75.6937 4.7998H86.1882C89.4876 4.7998 92.1747 7.48504 92.1747 10.7845V55.1596C92.1747 58.4591 89.4894 61.1443 86.1882 61.1443Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M31.9729 19.7093V29.4174H17.0429V19.7093H31.9729ZM32.6886 16.6997H16.3272C15.0607 16.6997 14.0333 17.7271 14.0333 18.9936V30.1313C14.0333 31.3978 15.0607 32.4252 16.3272 32.4252H32.6886C33.9551 32.4252 34.9825 31.3978 34.9825 30.1313V18.9936C34.9825 17.7271 33.9551 16.6997 32.6886 16.6997Z"
                  fill="#1C7ABF"
                />
                <path d="M28.3437 38.6216H14.0333V41.6312H28.3437V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5132 38.6216H32.201V41.6312H46.5132V38.6216Z" fill="#1C7ABF" />
                <path d="M82.8492 38.6216H68.5388V41.6312H82.8492V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5134 46.3169H14.0333V49.3265H46.5134V46.3169Z" fill="#1C7ABF" />
                <path d="M82.8489 16.939H58.6782V19.9485H82.8489V16.939Z" fill="#1C7ABF" />
                <path
                  d="M21.19 32.202C20.3584 32.202 19.6862 31.528 19.6862 30.6981V18.4443C19.6862 17.6126 20.3602 16.9404 21.19 16.9404C22.0199 16.9404 22.6939 17.6144 22.6939 18.4443V30.6981C22.6939 31.528 22.0199 32.202 21.19 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M27.9704 32.202C27.1388 32.202 26.4666 31.528 26.4666 30.6981V18.4443C26.4666 17.6126 27.1406 16.9404 27.9704 16.9404C28.8003 16.9404 29.4743 17.6144 29.4743 18.4443V30.6981C29.4743 31.528 28.8003 32.202 27.9704 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.4458 51.2082C52.4061 62.796 61.7678 72.2219 73.3556 72.2616C84.9434 72.3012 94.3694 62.9396 94.409 51.3518C94.4487 39.7639 85.087 30.338 73.4992 30.2983C61.9114 30.2587 52.4854 39.6203 52.4458 51.2082Z"
                  fill="white"
                />
                <path
                  d="M73.3947 75.0901C67.9391 75.0901 62.7317 73.1333 58.5934 69.5022C54.0799 65.5396 51.3784 60.0568 50.9888 54.0631C50.5993 48.0693 52.5652 42.2821 56.5278 37.7687C60.4904 33.2552 65.9732 30.5537 71.967 30.1641C77.9625 29.7728 83.7479 31.7405 88.2613 35.7049C92.7748 39.6675 95.4763 45.1503 95.8659 51.1441C96.2554 57.1378 94.2877 62.9232 90.3251 67.4385C86.3625 71.9519 80.8797 74.6534 74.8859 75.043C74.3877 75.0756 73.8894 75.0919 73.3929 75.0919L73.3947 75.0901ZM73.4382 33.1338C68.0225 33.1338 62.6339 35.3733 58.789 39.7527C55.3573 43.6628 53.6523 48.6745 53.9911 53.8674C54.3281 59.0584 56.6691 63.8074 60.5792 67.241C64.4893 70.6727 69.5118 72.3777 74.6921 72.0389C79.8831 71.7019 84.6321 69.3609 88.0657 65.4508C91.4974 61.5407 93.2024 56.529 92.8636 51.338C92.5266 46.1469 90.1874 41.3979 86.2773 37.9643C82.5847 34.7247 78.0042 33.1338 73.44 33.1338H73.4382Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M89.446 51.1662C89.8247 55.397 88.5364 59.7672 85.5142 63.2117C79.6545 69.8867 69.4934 70.548 62.8183 64.6884C56.1433 58.8287 55.482 48.6676 61.3416 41.9925C67.2013 35.3175 77.3624 34.6562 84.0375 40.5158C84.8728 41.2479 85.612 42.0487 86.257 42.9003L89.446 51.1662Z"
                  fill="white"
                />
                <path
                  d="M73.4016 70.1917C69.1346 70.1917 65.0615 68.6624 61.8254 65.8214C58.2941 62.723 56.1832 58.4343 55.877 53.7469C55.5726 49.0595 57.1109 44.5334 60.2092 41.002C66.607 33.7145 77.7393 32.9898 85.0286 39.3876C85.9237 40.1722 86.7408 41.0491 87.4547 41.9931C87.9566 42.6563 87.828 43.5985 87.1648 44.1004C86.5017 44.6004 85.5595 44.4736 85.0576 43.8104C84.4633 43.0277 83.7874 42.2993 83.0427 41.6489C77.0019 36.3472 67.7739 36.9452 62.4705 42.9878C59.9012 45.9141 58.6256 49.6665 58.8793 53.5512C59.133 57.4377 60.8832 60.9926 63.8095 63.5601C69.8503 68.8635 79.0801 68.262 84.3817 62.2211C87.0398 59.1934 88.3045 55.316 87.9457 51.3008C87.8715 50.4746 88.4821 49.7426 89.3101 49.6683C90.1418 49.5958 90.8683 50.2046 90.9426 51.0326C91.3757 55.874 89.8482 60.5524 86.643 64.2051C83.5446 67.7347 79.2559 69.8474 74.5685 70.1536C74.1789 70.179 73.7876 70.1917 73.4016 70.1917Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M74.6101 62.1789H70.758C68.5475 62.1789 66.7483 60.6008 66.7483 58.6621V56.2903H69.7578V58.6621C69.7578 58.836 70.1474 59.1712 70.758 59.1712H74.6101C75.2207 59.1712 75.6103 58.8378 75.6103 58.6621V56.9027C75.6103 55.8772 74.7623 54.935 73.4487 54.5001L71.5462 53.8714C68.9787 53.0252 67.319 50.9597 67.319 48.6115V46.5423C67.319 44.6017 69.1164 43.0254 71.327 43.0254H74.6119C76.8224 43.0254 78.6198 44.6036 78.6198 46.5423V48.7582H75.6103V46.5423C75.6103 46.3683 75.2207 46.0331 74.6101 46.0331H71.3251C70.7145 46.0331 70.325 46.3665 70.325 46.5423V48.6115C70.325 49.637 71.1729 50.5792 72.4866 51.014L74.3891 51.6428C76.9565 52.4889 78.6162 54.5545 78.6162 56.9027V58.6621C78.6162 60.6008 76.8188 62.1789 74.6083 62.1789H74.6101Z"
                  fill="#1C7ABF"
                />
                <path d="M74.5757 40.6294H71.5662V44.5304H74.5757V40.6294Z" fill="#1C7ABF" />
                <path d="M74.5757 60.6743H71.5662V64.5753H74.5757V60.6743Z" fill="#1C7ABF" />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Create and add custom line items
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[69.256px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="75"
                viewBox="0 0 70 75"
                fill="none"
              >
                <path
                  d="M34.9614 74.9999C15.8708 74.9999 0.333252 59.4696 0.333252 40.3718C0.333252 21.2741 15.8636 5.74365 34.9614 5.74365C54.0591 5.74365 69.5895 21.2741 69.5895 40.3718C69.5895 59.4696 54.0591 74.9999 34.9614 74.9999ZM34.9614 7.91287C17.0599 7.91287 2.49526 22.4776 2.49526 40.3791C2.49526 58.2805 17.0599 72.8451 34.9614 72.8451C52.8628 72.8451 67.4275 58.2805 67.4275 40.3791C67.4275 22.4776 52.8628 7.91287 34.9614 7.91287ZM34.9614 67.959C19.7552 67.959 7.38133 55.5852 7.38133 40.3791C7.38133 25.1729 19.7552 12.799 34.9614 12.799C50.1675 12.799 62.5414 25.1729 62.5414 40.3791C62.5414 55.5852 50.1675 67.959 34.9614 67.959ZM34.9614 14.9538C20.9443 14.9538 9.54334 26.3548 9.54334 40.3718C9.54334 54.3889 20.9443 65.7898 34.9614 65.7898C48.9784 65.7898 60.3794 54.3889 60.3794 40.3718C60.3794 26.3548 48.9784 14.9538 34.9614 14.9538Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M35.2062 24.5316C34.6081 24.5316 34.1252 24.0488 34.1252 23.4506V18.6005C34.1252 18.0024 34.6081 17.5195 35.2062 17.5195C35.8044 17.5195 36.2873 18.0024 36.2873 18.6005V23.4506C36.2873 24.0488 35.8044 24.5316 35.2062 24.5316Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M35.2062 63.2313C34.6081 63.2313 34.1252 62.7485 34.1252 62.1503V57.3002C34.1252 56.7021 34.6081 56.2192 35.2062 56.2192C35.8044 56.2192 36.2873 56.7021 36.2873 57.3002V62.1503C36.2873 62.7485 35.8044 63.2313 35.2062 63.2313Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.2361 29.4823C22.9622 29.4823 22.6812 29.3743 22.4722 29.1653L19.0418 25.7349C18.6166 25.3097 18.6166 24.6251 19.0418 24.2071C19.467 23.7819 20.1516 23.7819 20.5696 24.2071L24 27.6375C24.4252 28.0627 24.4252 28.7473 24 29.1653C23.791 29.3743 23.5099 29.4823 23.2361 29.4823Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M50.5999 56.8535C50.3261 56.8535 50.045 56.7454 49.836 56.5364L46.4056 53.106C45.9804 52.6808 45.9804 51.9962 46.4056 51.5782C46.8308 51.153 47.5154 51.153 47.9334 51.5782L51.3638 55.0086C51.789 55.4338 51.789 56.1184 51.3638 56.5364C51.1548 56.7454 50.8738 56.8535 50.5999 56.8535Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M18.2779 41.453H13.4277C12.8295 41.453 12.3467 40.9702 12.3467 40.372C12.3467 39.7739 12.8295 39.291 13.4277 39.291H18.2779C18.876 39.291 19.3589 39.7739 19.3589 40.372C19.3589 40.9702 18.876 41.453 18.2779 41.453Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M56.9777 41.453H52.1276C51.5295 41.453 51.0466 40.9702 51.0466 40.372C51.0466 39.7739 51.5295 39.291 52.1276 39.291H56.9777C57.5759 39.291 58.0587 39.7739 58.0587 40.372C58.0587 40.9702 57.5759 41.453 56.9777 41.453Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.8057 56.8535C19.5319 56.8535 19.2508 56.7454 19.0418 56.5364C18.6166 56.1112 18.6166 55.4266 19.0418 55.0086L22.4722 51.5782C22.8974 51.153 23.582 51.153 24 51.5782C24.418 52.0034 24.4252 52.688 24 53.106L20.5696 56.5364C20.3606 56.7454 20.0796 56.8535 19.8057 56.8535Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M47.1695 29.4823C46.8957 29.4823 46.6146 29.3743 46.4056 29.1653C45.9804 28.7401 45.9804 28.0555 46.4056 27.6375L49.836 24.2071C50.2612 23.7819 50.9458 23.7819 51.3638 24.2071C51.789 24.6323 51.789 25.3169 51.3638 25.7349L47.9334 29.1653C47.7244 29.3743 47.4434 29.4823 47.1695 29.4823Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M46.636 41.8204H35.0261C34.4279 41.8204 33.9451 41.3376 33.9451 40.7394V29.1293C33.9451 28.5312 34.4279 28.0483 35.0261 28.0483C35.6242 28.0483 36.1071 28.5312 36.1071 29.1293V39.6584H46.636C47.2342 39.6584 47.717 40.1412 47.717 40.7394C47.717 41.3376 47.2342 41.8204 46.636 41.8204Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M39.5015 7.91293H30.5869C29.9887 7.91293 29.5059 7.43008 29.5059 6.83193V1.081C29.5059 0.482848 29.9887 0 30.5869 0H39.5015C40.0997 0 40.5825 0.482848 40.5825 1.081V6.83193C40.5825 7.43008 40.0997 7.91293 39.5015 7.91293ZM31.6679 5.75093H38.4205V2.16201H31.6679V5.75093Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Prorated billing and trial options
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[65.884px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="67"
                height="75"
                viewBox="0 0 67 75"
                fill="none"
              >
                <path
                  d="M48.0818 4.73525V36.9521C48.0818 37.6087 47.5426 38.1319 46.886 38.1319C46.7348 38.1319 46.5657 38.098 46.4305 38.0482C44.0887 37.2742 41.5779 37.1549 39.1846 37.7279C36.8589 38.2671 34.6523 39.4469 32.8159 41.2672C30.2215 43.8617 28.9225 47.2835 28.9225 50.6858C28.9225 54.0881 30.2197 57.494 32.8159 60.1044C34.0971 61.3856 35.5616 62.3448 37.1293 62.9854C38.6632 63.6082 40.2629 63.9462 41.8982 63.9961H42.2363C42.8929 63.9961 43.4161 64.5353 43.4161 65.1919H43.4321C43.4321 65.8325 42.8929 66.3716 42.2363 66.3716H1.84652C1.18991 66.3716 0.666748 65.8325 0.666748 65.1919V4.73347C0.666748 4.07686 1.18991 3.55371 1.84652 3.55371H11.7883C12.4449 3.55371 12.9841 4.07686 12.9841 4.73347V7.09302H37.8037V4.73347C37.8037 4.07686 38.3269 3.55371 38.9835 3.55371H46.886C47.5426 3.55371 48.0818 4.07686 48.0818 4.73347V4.73525ZM45.7062 35.3844V5.93105H40.1793V8.27279C40.1793 8.9294 39.6401 9.46858 38.9835 9.46858H11.7883C11.1317 9.46858 10.6085 8.9294 10.6085 8.27279V5.93105H3.0423V63.9961H33.9281C32.9334 63.3733 32.0081 62.6312 31.1486 61.7718C28.0826 58.7058 26.5488 54.6949 26.5488 50.6841C26.5488 46.6732 28.0826 42.6463 31.1486 39.5803C33.2893 37.4397 35.8997 36.0428 38.6472 35.4022C40.9729 34.863 43.3823 34.863 45.708 35.3862L45.7062 35.3844Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M11.7865 0H38.9817C39.6383 0 40.1775 0.539177 40.1775 1.19579V8.27263C40.1775 8.92925 39.6383 9.46842 38.9817 9.46842H11.7865C11.1298 9.46842 10.6067 8.92925 10.6067 8.27263V1.19579C10.6067 0.539177 11.1281 0 11.7865 0ZM37.8037 2.39157H12.984V7.09286H37.8037V2.39157Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M31.147 39.5799C34.1969 36.53 38.2238 34.9961 42.2347 34.9961C46.2456 34.9961 50.2725 36.53 53.3384 39.5799C56.3884 42.6459 57.9223 46.6746 57.9223 50.6837C57.9223 54.6928 56.3884 58.7036 53.3384 61.7714C50.2725 64.8374 46.2456 66.3713 42.2347 66.3713C38.2238 66.3713 34.2147 64.8374 31.147 61.7714C28.081 58.7054 26.5471 54.6945 26.5471 50.6837C26.5471 46.6728 28.081 42.6459 31.147 39.5799ZM42.2347 37.3734C38.8306 37.3734 35.4265 38.6706 32.8161 41.2651C30.2217 43.8595 28.9227 47.2814 28.9227 50.6837C28.9227 54.086 30.2199 57.4918 32.8161 60.1023C35.4283 62.6967 38.8306 63.9939 42.2347 63.9939C45.6388 63.9939 49.0589 62.6967 51.6533 60.1023C54.2477 57.49 55.5467 54.086 55.5467 50.6837C55.5467 47.2814 54.2495 43.8595 51.6533 41.2651C49.0589 38.6706 45.637 37.3734 42.2347 37.3734Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M34.4156 42.8488C36.5723 40.6921 39.4034 39.6138 42.2345 39.6138C45.0656 39.6138 47.8967 40.6921 50.0694 42.8488C52.226 45.0233 53.3044 47.8526 53.3044 50.6837C53.3044 53.5148 52.226 56.3459 50.0694 58.5025C47.8967 60.677 45.0656 61.7554 42.2345 61.7554C39.4034 61.7554 36.5723 60.677 34.4156 58.5025C32.2429 56.3459 31.1646 53.5148 31.1646 50.6837C31.1646 47.8526 32.2429 45.0215 34.4156 42.8488ZM42.2345 41.9893C40.0102 41.9893 37.7858 42.8488 36.0847 44.5339C34.3996 46.2351 33.5401 48.4594 33.5401 50.6837C33.5401 52.908 34.3996 55.1323 36.0847 56.8334C37.7858 58.5186 40.0102 59.378 42.2345 59.378C44.4588 59.378 46.6831 58.5186 48.3842 56.8334C50.0693 55.1305 50.9288 52.908 50.9288 50.6837C50.9288 48.4594 50.0693 46.2351 48.3842 44.5339C46.6831 42.8488 44.4588 41.9893 42.2345 41.9893Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.94 58.1826L58.1412 61.3838C58.6127 61.8393 58.6127 62.5973 58.1412 63.0529L54.6196 66.5904C54.1481 67.062 53.3901 67.062 52.9345 66.5904L49.7333 63.3892C49.2618 62.9337 49.2618 62.1756 49.7333 61.7041C49.8009 61.6364 49.8685 61.5866 49.9522 61.535C50.2547 61.3161 50.5412 61.0973 50.8116 60.8784C51.0643 60.6595 51.3508 60.4068 51.6551 60.1026L51.7067 60.0349C51.9754 59.7645 52.2121 59.5118 52.4309 59.2431C52.6658 58.9726 52.9025 58.6701 53.1392 58.332C53.5093 57.7928 54.2513 57.6754 54.7905 58.0455C54.8421 58.0793 54.9079 58.1292 54.9417 58.1808L54.94 58.1826ZM55.6304 62.2094L54.2157 60.8108C53.9452 61.1311 53.6765 61.4336 53.39 61.7041L53.3385 61.7717C53.052 62.0582 52.7157 62.3607 52.3615 62.665L53.7762 64.0796L55.6304 62.2094Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M64.8138 73.2632L64.1572 73.9038C63.4329 74.6458 62.472 74.9999 61.5289 74.9999C60.5858 74.9999 59.6089 74.6458 58.8829 73.9038L52.952 67.9729C52.5143 67.5351 52.2954 66.996 52.2954 66.439C52.2954 65.882 52.4983 65.3429 52.936 64.9051L56.4735 61.3836C56.8953 60.9459 57.4344 60.727 58.0074 60.743C58.5466 60.743 59.0858 60.9619 59.5235 61.3836L65.4722 67.3305C66.1964 68.0547 66.5505 69.0156 66.5505 69.9587C66.5505 70.9018 66.1964 71.8788 65.4722 72.6048L65.4046 72.6546L64.8156 73.2614L64.8138 73.2632ZM62.472 72.2346L63.7194 70.9873L63.7871 70.9196C64.0397 70.6509 64.175 70.3128 64.175 69.9587C64.175 69.6046 64.0397 69.2683 63.7871 69.0156L57.9914 63.22L54.7724 66.439L60.5698 72.2346C60.8385 72.4873 61.1766 72.6226 61.5307 72.6226C61.8848 72.6226 62.2211 72.4873 62.4738 72.2346H62.472Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M17.6498 23.3712V30.464C17.6498 31.1207 17.1107 31.6438 16.454 31.6438H10.9431C10.2865 31.6438 9.74731 31.1207 9.74731 30.464V23.3712C9.74731 22.7146 10.2865 22.1914 10.9431 22.1914H16.454C17.1107 22.1914 17.6498 22.7146 17.6498 23.3712ZM15.2743 29.2682V24.567H12.1229V29.2682H15.2743Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M28.3156 19.2428V30.4657C28.3156 31.1223 27.7764 31.6455 27.1198 31.6455H21.6107C20.9541 31.6455 20.4309 31.1223 20.4309 30.4657V19.2428C20.4309 18.5861 20.9541 18.063 21.6107 18.063H27.1198C27.7764 18.063 28.3156 18.5861 28.3156 19.2428ZM25.9401 29.2681V20.4385H22.8065V29.2681H25.9401Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M38.9817 15.7197V30.4642C38.9817 31.1208 38.4603 31.644 37.8019 31.644H32.275C31.6184 31.644 31.0952 31.1208 31.0952 30.4642V15.7197C31.0952 15.0631 31.6184 14.5239 32.275 14.5239H37.8019C38.4585 14.5239 38.9817 15.0631 38.9817 15.7197ZM36.6061 29.2684V16.8995H33.4725V29.2684H36.6061Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.9526 38.5532C21.6092 38.5532 22.1323 39.0924 22.1323 39.749C22.1323 40.4056 21.6092 40.927 20.9526 40.927H8.33274C7.67613 40.927 7.13696 40.3878 7.13696 39.749C7.13696 39.1102 7.67613 38.5532 8.33274 38.5532H20.9526Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.9526 46.1855C21.6092 46.1855 22.1323 46.7087 22.1323 47.3653C22.1323 48.0219 21.6092 48.5611 20.9526 48.5611H8.33274C7.67613 48.5611 7.13696 48.0219 7.13696 47.3653C7.13696 46.7087 7.67613 46.1855 8.33274 46.1855H20.9526Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.9526 53.8013C21.6092 53.8013 22.1323 54.3404 22.1323 54.9971C22.1323 55.6537 21.6092 56.1768 20.9526 56.1768H8.33274C7.67613 56.1768 7.13696 55.6537 7.13696 54.9971C7.13696 54.3404 7.67613 53.8013 8.33274 53.8013H20.9526Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">Advanced filtering</p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[73.773px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="74"
                height="76"
                viewBox="0 0 74 76"
                fill="none"
              >
                <path
                  d="M10.3571 75.0918C10.0228 75.0918 9.69579 75.0772 9.36872 75.0481C4.02665 74.6193 0 70.84 0 66.2684C0 65.6651 0.486965 65.1781 1.09022 65.1781C1.69348 65.1781 2.18044 65.6651 2.18044 66.2684C2.18044 69.6989 5.34944 72.5406 9.54315 72.875C9.81207 72.8968 10.0809 72.9114 10.3571 72.9114C14.8706 72.9114 18.5337 69.9315 18.5337 66.2684V62.7723C18.5337 62.1691 19.0207 61.6821 19.624 61.6821C20.2272 61.6821 20.7142 62.1691 20.7142 62.7723V66.2684C20.7142 71.138 16.0698 75.0918 10.3571 75.0918Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.1329 75.0918H10.3642C9.76098 75.0918 9.27402 74.6048 9.27402 74.0016C9.27402 73.3983 9.76098 72.9114 10.3642 72.9114C14.8777 72.9114 18.5409 69.9315 18.5409 66.2684V62.7723C18.5409 62.1691 19.0279 61.6821 19.6311 61.6821H63.3998C64.003 61.6821 64.49 62.1691 64.49 62.7723V66.2684C64.49 71.138 59.8457 75.0918 54.1329 75.0918ZM17.1746 72.9114H54.1329C58.6464 72.9114 62.3096 69.9315 62.3096 66.2684V63.8626H20.7213V66.2684C20.7213 68.914 19.3477 71.2906 17.1746 72.9114Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M1.09022 67.4164C0.486965 67.4164 0 66.9295 0 66.3262V1.18202C0 0.578762 0.486965 0.0917969 1.09022 0.0917969H56.2773C56.8805 0.0917969 57.3675 0.578762 57.3675 1.18202V62.7722C57.3675 63.3754 56.8805 63.8624 56.2773 63.8624C55.674 63.8624 55.1871 63.3754 55.1871 62.7722V2.27224H2.18044V66.3262C2.18044 66.9295 1.69348 67.4164 1.09022 67.4164Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M72.6811 66.4282C72.4049 66.4282 72.1214 66.3192 71.9107 66.1084L33.6949 27.8925C33.2661 27.4637 33.2661 26.7732 33.6949 26.3516C34.1238 25.9301 34.8141 25.9228 35.2357 26.3516L73.4516 64.5675C73.8804 64.9963 73.8804 65.6869 73.4516 66.1084C73.2408 66.3192 72.9573 66.4282 72.6811 66.4282Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M32.4957 32.544C32.1468 32.544 31.8125 32.3769 31.609 32.0862L29.036 28.4449L24.5808 28.5029C24.1883 28.5319 23.7812 28.2777 23.5922 27.907C23.4032 27.5363 23.4397 27.0929 23.6868 26.7586L26.3541 23.1827L24.9223 18.9599C24.7915 18.5674 24.8933 18.1313 25.1841 17.8406C25.4748 17.5499 25.9107 17.4482 26.3032 17.579L30.526 19.0108L34.1021 16.3433C34.4364 16.0962 34.8797 16.0599 35.2503 16.2489C35.621 16.4379 35.8536 16.823 35.8464 17.2373L35.7882 21.6927L39.4295 24.2657C39.7711 24.5055 39.9384 24.9198 39.8802 25.3268C39.8148 25.7338 39.5241 26.0754 39.1243 26.1989L34.8651 27.5218L33.5424 31.7808C33.4189 32.1733 33.0844 32.4713 32.6701 32.5367C32.612 32.544 32.5538 32.5513 32.4957 32.5513V32.544ZM29.5884 26.2571C29.9446 26.2571 30.2718 26.4315 30.4753 26.7149L32.1034 29.0191L32.9391 26.3224C33.0481 25.9808 33.3171 25.7119 33.6587 25.6029L36.3551 24.7671L34.0512 23.1391C33.7604 22.9283 33.5859 22.594 33.5932 22.2378L33.6296 19.4177L31.3693 21.104C31.0785 21.3147 30.7078 21.3801 30.3662 21.2638L27.6915 20.3553L28.6001 23.0301C28.7164 23.3717 28.6582 23.7423 28.4402 24.033L26.7539 26.2935L29.5739 26.2571H29.5884Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.6577 17.4048C23.3816 17.4048 23.0981 17.2956 22.8873 17.0848L19.2241 13.4217C18.7953 12.9928 18.7953 12.3025 19.2241 11.8809C19.6529 11.4594 20.3435 11.4521 20.765 11.8809L24.4282 15.5441C24.857 15.9729 24.857 16.6633 24.4282 17.0848C24.2174 17.2956 23.9339 17.4048 23.6577 17.4048Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.6992 24.5486H15.5171C14.9138 24.5486 14.4269 24.0616 14.4269 23.4584C14.4269 22.8551 14.9138 22.3682 15.5171 22.3682H20.6992C21.3024 22.3682 21.7894 22.8551 21.7894 23.4584C21.7894 24.0616 21.3024 24.5486 20.6992 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M46.0872 24.5486H40.9049C40.3017 24.5486 39.8147 24.0616 39.8147 23.4584C39.8147 22.8551 40.3017 22.3682 40.9049 22.3682H46.0872C46.6904 22.3682 47.1774 22.8551 47.1774 23.4584C47.1774 24.0616 46.6904 24.5486 46.0872 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.9946 35.3572C19.7184 35.3572 19.4349 35.2482 19.2241 35.0374C18.7953 34.6086 18.7953 33.9181 19.2241 33.4965L22.8873 29.8333C23.3161 29.4045 24.0067 29.4045 24.4282 29.8333C24.857 30.2622 24.857 30.9527 24.4282 31.3743L20.765 35.0374C20.5543 35.2482 20.2708 35.3572 19.9946 35.3572Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M37.9469 17.4045C37.6707 17.4045 37.3873 17.2954 37.1766 17.0846C36.7477 16.6558 36.7477 15.9654 37.1766 15.5439L40.8396 11.8807C41.2684 11.4519 41.9589 11.4519 42.3805 11.8807C42.802 12.3095 42.8093 12.9999 42.3805 13.4214L38.7173 17.0846C38.5065 17.2954 38.223 17.4045 37.9469 17.4045Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 39.8337C30.199 39.8337 29.712 39.3468 29.712 38.7435V33.5614C29.712 32.9582 30.199 32.4712 30.8022 32.4712C31.4055 32.4712 31.8924 32.9582 31.8924 33.5614V38.7435C31.8924 39.3468 31.4055 39.8337 30.8022 39.8337Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 14.4467C30.199 14.4467 29.712 13.9597 29.712 13.3565V8.1742C29.712 7.57095 30.199 7.08398 30.8022 7.08398C31.4055 7.08398 31.8924 7.57095 31.8924 8.1742V13.3565C31.8924 13.9597 31.4055 14.4467 30.8022 14.4467Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Set it and forget it
            </p>
          </article>
        </section>
        <section className="self-stretch flex flex-col gap-[60px] items-start">
          <h2 className="text-black text-center text-[35px] font-semibold font-customFont">
            Save time and boost your bottom line by simplifying your payment process using Recurring
            Billing.
          </h2>
        </section>
        <section className="flex w-full max-md:w-auto px-[122px] py-[100px] flex-col items-start gap-[50px] bg-[url('./img/softwarePartners.png')] bg-cover bg-bottom md:rounded-3xl">
          <h1 className="flex flex-col items-start justify-center text-white text-[55px] font-medium font-customFont">
            How does Recurring Billing work?
          </h1>
          <article className="inline-flex flex-col justify-center items-start gap-[50px] self-stretch">
            <div className="self-stretch justify-start items-start gap-[45px] inline-flex">
              <div className="w-[30px] h-[30px] relative">
                <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p className="text-white font-customFont text-[25px] font-light">
                Create Subscriptions <span className="font-bold">tailored to your needs</span> by
                using custom line items. Use the Invoice generated each billing cycle to track the
                status of payments within the Subscription.
              </p>
            </div>
          </article>
          <article className="inline-flex flex-col justify-center items-start gap-[50px] self-stretch">
            <div className="self-strech justify-start items-start gap-[45px] inline-flex">
              <div className="w-[30px] h-[30px] relative">
                <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p className="text-white font-customFont text-[25px] font-light">
                You can easily link Subscriptions to a saved or new Customer. Subscriptions appear
                in the Customer&apos;s record, making it <span className="font-bold">simple</span>{' '}
                to manage recurring payments.
              </p>
            </div>
          </article>
          <article className="inline-flex flex-col justify-center items-start gap-[50px] self-stretch">
            <div className="self-strech justify-start items-start gap-[33px] inline-flex">
              <div className="w-[30px] h-[30px] relative">
                <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p className="text-white font-customFont text-[25px] font-light">
                Choose the schedule that's <span className="font-bold">right for you</span> with
                options to bill on a daily, weekly, monthly, quarterly or annual basis.
              </p>
            </div>
          </article>
          <article className="inline-flex flex-col justify-center items-start gap-[50px] self-stretch">
            <div className="self-strech justify-start items-start gap-[45px] inline-flex">
              <div className="w-[30px] h-[30px] relative">
                <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_686_3709)">
                      <mask
                        id="mask0_686_3709"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="30"
                        height="31"
                      >
                        <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_686_3709)">
                        <path
                          d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_686_3709">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.0917969)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p className="text-white font-customFont text-[25px] font-light">
                Offer <span className="font-bold">flexible billing options</span>, like trial
                periods and prorated charges. Allow customers to pay using a saved payment method or
                send them an Invoice automatically each billing cycle.
              </p>
            </div>
          </article>
        </section>
        <section
          className="flex p-[60px] flex-col gap-[50px] self-stretch rounded-[10px] border-[2px] border-solid border-[#4452A3]"
          style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
        >
          <p className="text-center text-black text-[35px] font-normal font-customFont">
            Automating payments can cut down on time spent managing invoices by up to{' '}
            <span className="font-semibold text-[#C0F]">80%</span>.
          </p>
        </section>
        <div className="w-[1054px] h-[0px] border border-black"></div>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1296px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              Access Subscription details directly from the Subscription or through an associated{' '}
              <span className="font-semibold text-[#C0F]">Customer</span>.
            </h2>
            <Link
              to="/features/customers"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452a3] learn1"
            >
              <p className="text-xl font-medium text-white font-customFont">Learn More</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[728px] h-[449.64px] flex-shrink-0">
            <img
              className="w-[728px] h-[449.64px]"
              src={customerRecurring}
              alt="customer details mockup"
            />
          </figure>
        </section>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1296px]:grid">
          <figure className="w-[558.37px] h-[550.90px] flex-shrink-0 max-[1296px]:hidden">
            <img
              className="w-[558.37px] h-[550.90px]"
              src={recurringReporting}
              alt="Reporting mockup for subscriptions"
            />
          </figure>
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              Filter transactions by Subscription ID to view all related transaction details in{' '}
              <span className="font-semibold text-[#C0F]">Reporting</span>.
            </h2>
            <Link
              to="/features/reporting"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452a3] learn2"
            >
              <p className="text-xl font-medium text-white font-customFont">Learn More</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
            <figure className="w-[558.37px] h-[550.90px] flex-shrink-0 min-[1296px]:hidden">
              <img
                className="w-[558.37px] h-[550.90px]"
                src={recurringReporting}
                alt="Reporting mockup for subscriptions"
              />
            </figure>
          </article>
        </section>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1296px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              Autogenerate <span className="font-semibold text-[#C0F]">Invoices</span> each billing
              cycle to automatically charge Customers or send them for manual payment.
            </h2>
            <Link
              to="/features/invoicing"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452a3] learn3"
            >
              <p className="text-xl font-medium text-white font-customFont">Learn More</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[742px] h-[402.95px] flex-shrink-0">
            <img src={recurringInvoices} alt="mockup of recurring invoices" />
          </figure>
        </section>
        <section className="h-[157px] max-md:h-auto flex-col justify-start items-center gap-[25px] inline-flex">
          <h1 className="self-stretch text-center text-black text-[45px] font-medium font-customFont">
            Ready to get started?
          </h1>
          <Link className="flex items-start gap-4 px-4 py-2 rounded-[20px] bg-[#4452a3] bookDemo">
            <p className="text-xl font-medium text-center text-white font-customFont">
              Book a demo today!
            </p>
            <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
              <svg
                className="absolute arrowNav top-2"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
