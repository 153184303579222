import TerminalImg from '../img/TerminalImage.png';
import MobileTerminal from '../img/MobileTerminal.png';
import physicalTerminal from '../img/PhysicalTerminalVirtual.png';
import Header from '../Header';
import MobileNavigation from '../MobileNavigation';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
export default function Terminal() {
  return (
    <>
      <MobileNavigation />
      <main className="w-full h-full px-[200px] py-10 flex-col justify-start items-center gap-[75px] inline-flex">
        <Header />

        <section className="inline-flex items-center self-stretch justify-between max-[1058px]:justify-center max-[1058px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-6 max-[1058px]:items-center">
            <h2 className="text-[#4452A3] text-[25px] font-light font-customFont">TERMINAL</h2>
            <h1 className="text-black text-[45px] font-medium font-customFont">
              Process transactions from <span className="text-[#C0F] font-semibold">anywhere</span>.
            </h1>
            <p className="text-black text-[25px] font-light font-customFont">
              Securely take payments on any device with an internet connection.
            </p>
            <Link
              to="/bookDemo"
              className="flex items-center justify-center gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] headerDemo"
            >
              <p className="text-xl font-medium text-white font-customFont">Book a Demo</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="w-[582px] h-[450px] justify-self-center flex-shrink-0">
            <img src={TerminalImg} alt="Hands holding tablet with terminal design" />
          </figure>
        </section>
        <section className="flex self-stretch justify-between max-[1516px]:justify-center max-[1516px]:gap-4 items-center max-[1516px]:grid cards">
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[96.88px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="97"
                height="76"
                viewBox="0 0 97 76"
                fill="none"
              >
                <path
                  d="M0.00181367 65.8509V12.6827C0.00181367 5.72861 5.63862 0.0917969 12.5927 0.0917969H84.2912C91.2453 0.0917969 96.8803 5.7286 96.8803 12.6809V53.2601C96.8803 60.2141 91.2435 65.8509 84.2894 65.8509H12.5909C5.63681 65.8509 0 72.805 0 65.8509H0.00181367Z"
                  fill="white"
                />
                <path
                  d="M86.1882 61.1443H10.6937C7.39422 61.1443 4.70898 58.4591 4.70898 55.1596V10.7845C4.70898 7.48504 7.39422 4.7998 10.6937 4.7998H67.1669C67.9967 4.7998 68.6707 5.47382 68.6707 6.30367C68.6707 7.13351 67.9967 7.80755 67.1669 7.80755H10.6937C9.0521 7.80755 7.71673 9.14293 7.71673 10.7845V55.1596C7.71673 56.8012 9.0521 58.1366 10.6937 58.1366H86.1882C87.8297 58.1366 89.1651 56.8012 89.1651 55.1596V10.7845C89.1651 9.14293 87.8297 7.80755 86.1882 7.80755H75.6937C74.8638 7.80755 74.1898 7.13351 74.1898 6.30367C74.1898 5.47382 74.8638 4.7998 75.6937 4.7998H86.1882C89.4876 4.7998 92.1747 7.48504 92.1747 10.7845V55.1596C92.1747 58.4591 89.4894 61.1443 86.1882 61.1443Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M31.9729 19.7093V29.4174H17.0429V19.7093H31.9729ZM32.6886 16.6997H16.3272C15.0607 16.6997 14.0333 17.7271 14.0333 18.9936V30.1313C14.0333 31.3978 15.0607 32.4252 16.3272 32.4252H32.6886C33.9551 32.4252 34.9825 31.3978 34.9825 30.1313V18.9936C34.9825 17.7271 33.9551 16.6997 32.6886 16.6997Z"
                  fill="#1C7ABF"
                />
                <path d="M28.3437 38.6216H14.0333V41.6312H28.3437V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5132 38.6216H32.201V41.6312H46.5132V38.6216Z" fill="#1C7ABF" />
                <path d="M82.8492 38.6216H68.5388V41.6312H82.8492V38.6216Z" fill="#1C7ABF" />
                <path d="M46.5134 46.3169H14.0333V49.3265H46.5134V46.3169Z" fill="#1C7ABF" />
                <path d="M82.8489 16.939H58.6782V19.9485H82.8489V16.939Z" fill="#1C7ABF" />
                <path
                  d="M21.19 32.202C20.3584 32.202 19.6862 31.528 19.6862 30.6981V18.4443C19.6862 17.6126 20.3602 16.9404 21.19 16.9404C22.0199 16.9404 22.6939 17.6144 22.6939 18.4443V30.6981C22.6939 31.528 22.0199 32.202 21.19 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M27.9704 32.202C27.1388 32.202 26.4666 31.528 26.4666 30.6981V18.4443C26.4666 17.6126 27.1406 16.9404 27.9704 16.9404C28.8003 16.9404 29.4743 17.6144 29.4743 18.4443V30.6981C29.4743 31.528 28.8003 32.202 27.9704 32.202Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M52.4458 51.2082C52.4061 62.796 61.7678 72.2219 73.3556 72.2616C84.9434 72.3012 94.3694 62.9396 94.409 51.3518C94.4487 39.7639 85.087 30.338 73.4992 30.2983C61.9114 30.2587 52.4854 39.6203 52.4458 51.2082Z"
                  fill="white"
                />
                <path
                  d="M73.3947 75.0901C67.9391 75.0901 62.7317 73.1333 58.5934 69.5022C54.0799 65.5396 51.3784 60.0568 50.9888 54.0631C50.5993 48.0693 52.5652 42.2821 56.5278 37.7687C60.4904 33.2552 65.9732 30.5537 71.967 30.1641C77.9625 29.7728 83.7479 31.7405 88.2613 35.7049C92.7748 39.6675 95.4763 45.1503 95.8659 51.1441C96.2554 57.1378 94.2877 62.9232 90.3251 67.4385C86.3625 71.9519 80.8797 74.6534 74.8859 75.043C74.3877 75.0756 73.8894 75.0919 73.3929 75.0919L73.3947 75.0901ZM73.4382 33.1338C68.0225 33.1338 62.6339 35.3733 58.789 39.7527C55.3573 43.6628 53.6523 48.6745 53.9911 53.8674C54.3281 59.0584 56.6691 63.8074 60.5792 67.241C64.4893 70.6727 69.5118 72.3777 74.6921 72.0389C79.8831 71.7019 84.6321 69.3609 88.0657 65.4508C91.4974 61.5407 93.2024 56.529 92.8636 51.338C92.5266 46.1469 90.1874 41.3979 86.2773 37.9643C82.5847 34.7247 78.0042 33.1338 73.44 33.1338H73.4382Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M89.446 51.1662C89.8247 55.397 88.5364 59.7672 85.5142 63.2117C79.6545 69.8867 69.4934 70.548 62.8183 64.6884C56.1433 58.8287 55.482 48.6676 61.3416 41.9925C67.2013 35.3175 77.3624 34.6562 84.0375 40.5158C84.8728 41.2479 85.612 42.0487 86.257 42.9003L89.446 51.1662Z"
                  fill="white"
                />
                <path
                  d="M73.4016 70.1917C69.1346 70.1917 65.0615 68.6624 61.8254 65.8214C58.2941 62.723 56.1832 58.4343 55.877 53.7469C55.5726 49.0595 57.1109 44.5334 60.2092 41.002C66.607 33.7145 77.7393 32.9898 85.0286 39.3876C85.9237 40.1722 86.7408 41.0491 87.4547 41.9931C87.9566 42.6563 87.828 43.5985 87.1648 44.1004C86.5017 44.6004 85.5595 44.4736 85.0576 43.8104C84.4633 43.0277 83.7874 42.2993 83.0427 41.6489C77.0019 36.3472 67.7739 36.9452 62.4705 42.9878C59.9012 45.9141 58.6256 49.6665 58.8793 53.5512C59.133 57.4377 60.8832 60.9926 63.8095 63.5601C69.8503 68.8635 79.0801 68.262 84.3817 62.2211C87.0398 59.1934 88.3045 55.316 87.9457 51.3008C87.8715 50.4746 88.4821 49.7426 89.3101 49.6683C90.1418 49.5958 90.8683 50.2046 90.9426 51.0326C91.3757 55.874 89.8482 60.5524 86.643 64.2051C83.5446 67.7347 79.2559 69.8474 74.5685 70.1536C74.1789 70.179 73.7876 70.1917 73.4016 70.1917Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M74.6101 62.1789H70.758C68.5475 62.1789 66.7483 60.6008 66.7483 58.6621V56.2903H69.7578V58.6621C69.7578 58.836 70.1474 59.1712 70.758 59.1712H74.6101C75.2207 59.1712 75.6103 58.8378 75.6103 58.6621V56.9027C75.6103 55.8772 74.7623 54.935 73.4487 54.5001L71.5462 53.8714C68.9787 53.0252 67.319 50.9597 67.319 48.6115V46.5423C67.319 44.6017 69.1164 43.0254 71.327 43.0254H74.6119C76.8224 43.0254 78.6198 44.6036 78.6198 46.5423V48.7582H75.6103V46.5423C75.6103 46.3683 75.2207 46.0331 74.6101 46.0331H71.3251C70.7145 46.0331 70.325 46.3665 70.325 46.5423V48.6115C70.325 49.637 71.1729 50.5792 72.4866 51.014L74.3891 51.6428C76.9565 52.4889 78.6162 54.5545 78.6162 56.9027V58.6621C78.6162 60.6008 76.8188 62.1789 74.6083 62.1789H74.6101Z"
                  fill="#1C7ABF"
                />
                <path d="M74.5757 40.6294H71.5662V44.5304H74.5757V40.6294Z" fill="#1C7ABF" />
                <path d="M74.5757 60.6743H71.5662V64.5753H74.5757V60.6743Z" fill="#1C7ABF" />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Accept Card and ACH payments
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[70.011px] h-[74.358px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="71"
                height="75"
                viewBox="0 0 71 75"
                fill="none"
              >
                <path
                  d="M1.8917 73.3529L0.340295 44.0266C0.272905 42.9255 0.700177 41.8501 1.51173 41.0858L7.33337 35.4126L8.83337 36.9126L3.17498 42.7892C2.84663 43.0975 2.69178 43.489 2.71616 43.8919L4.26756 73.2282L1.89026 73.3544L1.8917 73.3529Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M64.3264 74.7706H40.0401C36.9014 74.7706 34.3177 72.356 34.0467 69.2862L29.7882 65.9382C29.5014 65.7131 29.3336 65.3675 29.3336 65.0019V54.9091C29.3336 54.251 29.867 53.719 30.5237 53.719C31.1804 53.719 31.7138 54.2524 31.7138 54.9091V64.4226L35.9493 67.752C36.2361 67.9771 36.4039 68.3226 36.4039 68.6883V68.7528C36.4039 70.7573 38.0356 72.389 40.0401 72.389H64.3264C66.3309 72.389 67.9626 70.7573 67.9626 68.7528V16.5195C67.8805 15.6782 67.4117 15.0376 66.7367 14.5566C64.7711 13.2563 63.0456 12.4662 60.9586 12.3813L60.8805 10.2563C63.818 10.4126 65.3336 11.4126 67.7992 12.4245C69.3692 13.2074 70.3442 14.776 70.3442 16.5181V68.7514C70.3442 72.0693 67.6458 74.7677 64.3279 74.7677L64.3264 74.7706Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M37.6814 14.6928C36.9057 15.0427 36.4039 15.8241 36.4039 16.6801V34.9386C36.4039 35.5967 35.8705 36.1287 35.2138 36.1287C34.5571 36.1287 34.0237 35.5953 34.0237 34.9386V16.6801C34.0237 14.8907 35.0747 13.259 36.6993 12.5234C38.1299 11.8757 40.45 10.9826 43.5226 10.3193L43.8336 12.4126C43.8336 12.4126 40.0844 13.3047 37.6814 14.6928Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M59.7886 15.8903H44.5569C43.8988 15.8903 43.3668 15.3569 43.3668 14.7002V1.60352C43.3668 1.1533 43.6206 0.741786 44.0221 0.539615C44.425 0.337444 44.9053 0.379026 45.2667 0.648588L47.0934 2.00643L48.9215 0.647153C49.3431 0.334576 49.9195 0.334576 50.3424 0.647153L52.1706 2.00643L53.9987 0.647153C54.4203 0.334576 54.9981 0.334576 55.4182 0.647153L57.2478 2.00643L59.0774 0.647153C59.4387 0.379025 59.9205 0.337444 60.3219 0.539615C60.7249 0.741786 60.9772 1.1533 60.9772 1.60352V14.7002C60.9772 15.3583 60.4438 15.8903 59.7871 15.8903H59.7886ZM45.7484 13.5087H58.5985V3.96936L57.959 4.44396C57.5374 4.75653 56.961 4.75653 56.5395 4.44396L54.7099 3.08468L52.8818 4.44396C52.4602 4.75653 51.8838 4.75653 51.4608 4.44396L49.6327 3.08468L47.8045 4.44396C47.383 4.75653 46.8066 4.75797 46.3836 4.44396L45.747 3.97079V13.5087H45.7484Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M63.0294 45.8503H41.3153C40.6572 45.8503 40.1252 45.3169 40.1252 44.6602V19.0963C40.1252 18.4382 40.6586 17.9062 41.3153 17.9062H63.0294C63.6875 17.9062 64.2194 18.4396 64.2194 19.0963V44.6602C64.2194 45.3183 63.6861 45.8503 63.0294 45.8503ZM42.5054 43.4687H61.8393V20.285H42.5054V43.4687Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M45.9906 51.0276H41.8411C41.183 51.0276 40.651 50.4943 40.651 49.8376C40.651 49.1809 41.1844 48.6475 41.8411 48.6475H45.9906C46.6487 48.6475 47.1807 49.1809 47.1807 49.8376C47.1807 50.4943 46.6473 51.0276 45.9906 51.0276Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.2468 51.0276H50.0973C49.4392 51.0276 48.9072 50.4943 48.9072 49.8376C48.9072 49.1809 49.4406 48.6475 50.0973 48.6475H54.2468C54.905 48.6475 55.4369 49.1809 55.4369 49.8376C55.4369 50.4943 54.9035 51.0276 54.2468 51.0276Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M62.5044 51.0276H58.3549C57.6968 51.0276 57.1648 50.4943 57.1648 49.8376C57.1648 49.1809 57.6982 48.6475 58.3549 48.6475H62.5044C63.1625 48.6475 63.6945 49.1809 63.6945 49.8376C63.6945 50.4943 63.1611 51.0276 62.5044 51.0276Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M45.9909 56.0789H41.8413C41.1832 56.0789 40.6512 55.5455 40.6512 54.8888C40.6512 54.2321 41.1846 53.6987 41.8413 53.6987H45.9909C46.649 53.6987 47.1809 54.2321 47.1809 54.8888C47.1809 55.5455 46.6476 56.0789 45.9909 56.0789Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.2471 56.0789H50.0975C49.4394 56.0789 48.9075 55.5455 48.9075 54.8888C48.9075 54.2321 49.4408 53.6987 50.0975 53.6987H54.2471C54.9052 53.6987 55.4372 54.2321 55.4372 54.8888C55.4372 55.5455 54.9038 56.0789 54.2471 56.0789Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M62.5046 56.0789H58.3551C57.697 56.0789 57.165 55.5455 57.165 54.8888C57.165 54.2321 57.6984 53.6987 58.3551 53.6987H62.5046C63.1628 53.6987 63.6947 54.2321 63.6947 54.8888C63.6947 55.5455 63.1613 56.0789 62.5046 56.0789Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M45.9909 61.1321H41.8413C41.1832 61.1321 40.6512 60.5987 40.6512 59.942C40.6512 59.2853 41.1846 58.752 41.8413 58.752H45.9909C46.649 58.752 47.1809 59.2853 47.1809 59.942C47.1809 60.5987 46.6476 61.1321 45.9909 61.1321Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.2471 61.1321H50.0975C49.4394 61.1321 48.9075 60.5987 48.9075 59.942C48.9075 59.2853 49.4408 58.752 50.0975 58.752H54.2471C54.9052 58.752 55.4372 59.2853 55.4372 59.942C55.4372 60.5987 54.9038 61.1321 54.2471 61.1321Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M62.5046 61.1321H58.3551C57.697 61.1321 57.165 60.5987 57.165 59.942C57.165 59.2853 57.6984 58.752 58.3551 58.752H62.5046C63.1628 58.752 63.6947 59.2853 63.6947 59.942C63.6947 60.5987 63.1613 61.1321 62.5046 61.1321Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M45.9909 66.1834H41.8413C41.1832 66.1834 40.6512 65.65 40.6512 64.9933C40.6512 64.3366 41.1846 63.8032 41.8413 63.8032H45.9909C46.649 63.8032 47.1809 64.3366 47.1809 64.9933C47.1809 65.65 46.6476 66.1834 45.9909 66.1834Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.2471 66.1834H50.0975C49.4394 66.1834 48.9075 65.65 48.9075 64.9933C48.9075 64.3366 49.4408 63.8032 50.0975 63.8032H54.2471C54.9052 63.8032 55.4372 64.3366 55.4372 64.9933C55.4372 65.65 54.9038 66.1834 54.2471 66.1834Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M62.5046 66.1834H58.3551C57.697 66.1834 57.165 65.65 57.165 64.9933C57.165 64.3366 57.6984 63.8032 58.3551 63.8032H62.5046C63.1628 63.8032 63.6947 64.3366 63.6947 64.9933C63.6947 65.65 63.1613 66.1834 62.5046 66.1834Z"
                  fill="#1C7ABF"
                />
                <path d="M63.0292 13.5088H41.3152V15.8904H63.0292V13.5088Z" fill="#1C7ABF" />
                <path
                  d="M35.2026 58.9227C34.5445 58.9227 34.0126 58.3893 34.0126 57.7327V33.7116C34.0126 33.0534 34.546 32.5215 35.2026 32.5215C35.8593 32.5215 36.3927 33.0549 36.3927 33.7116V57.7327C36.3927 58.3908 35.8593 58.9227 35.2026 58.9227Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M8.83337 49.9126C6.24817 49.9126 6.71516 50.1672 6.68218 47.5762L6.33375 19.3942C6.31798 18.1295 6.79545 16.9337 7.68013 16.0289C8.56337 15.1228 9.74628 14.6152 11.0109 14.6008L26.1953 14.413C28.8077 14.3786 30.957 16.4777 30.99 19.0902L31.3384 47.2723C31.3542 48.5369 30.8767 49.7327 29.9935 50.6389C29.1103 51.5451 27.9273 52.0527 26.6627 52.067L19.3334 52.4126V49.9126L26.6312 49.684C27.2606 49.6768 27.8485 49.423 28.2872 48.9742C28.726 48.524 28.964 47.929 28.9568 47.2995L28.6084 19.1174C28.5926 17.8284 27.5388 16.7918 26.2541 16.7918L26.2555 16.7932H26.2254L11.041 16.981C10.4116 16.9882 9.82372 17.2405 9.38496 17.6908C8.94621 18.141 8.70819 18.736 8.71535 19.3641L9.06378 47.5461L9.33337 48.4126L8.83337 49.4126V49.9126Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.2657 20.9298L23.3073 24.2434L21.9595 24.2606L21.9179 20.9456L23.2657 20.9284V20.9298ZM25.6172 18.5195L19.509 18.5955L19.6094 26.6709L25.7176 26.5949L25.6172 18.5195Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M17.028 18.6194L14.6466 18.6489L14.7207 24.6261L17.1021 24.5966L17.028 18.6194Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M17.122 26.2068L14.7406 26.2363L14.8147 32.2135L17.1961 32.184L17.122 26.2068Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M13.5102 18.6648L11.1288 18.6943L11.2969 32.2588L13.6783 32.2293L13.5102 18.6648Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M26.3132 37.1565L23.9318 37.186L24.0569 47.2809L26.4383 47.2514L26.3132 37.1565Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M12.1393 72.7864L11.4497 64.5863C11.398 63.9669 11.8311 63.412 12.4433 63.3116C18.8669 62.2549 16.8954 49.4192 16.8753 49.2901C16.8294 48.9976 16.8925 48.6994 17.0531 48.4513C19.2182 45.1291 18.6934 40.7488 18.5199 39.3078C18.246 37.0222 17.3757 36.9706 16.9083 36.9419L15.5031 36.9219C13.1344 48.2764 6.19752 52.9134 5.89642 53.1113L4.59448 51.1183C4.66187 51.0738 11.3866 46.52 13.3538 35.5081C13.4556 34.9403 13.9503 34.5273 14.5252 34.5273H14.5439L16.9986 34.5646C18.5672 34.6564 20.4642 35.5009 20.8857 39.0253C21.0779 40.624 21.65 45.3872 19.2927 49.3618C19.6168 51.8209 20.7251 63.1912 13.9101 65.4022L14.5138 72.5871L12.1408 72.7864H12.1393Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Combine with physical terminals
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col justify-self-center items-start gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[80.952px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="82"
                height="76"
                viewBox="0 0 82 76"
                fill="none"
              >
                <g clipPath="url(#clip0_701_4159)">
                  <path
                    d="M41.0095 28.1976C36.3527 28.1976 32.1001 25.2673 30.4346 20.9041C29.9334 19.5927 29.6747 18.2246 29.6747 16.8404C29.6747 10.583 34.76 5.49121 41.0095 5.49121C47.259 5.49121 52.3443 10.583 52.3443 16.8404C52.3443 18.2246 52.0855 19.5846 51.5843 20.9041C49.9108 25.2673 45.6663 28.1976 41.0095 28.1976ZM41.0095 7.87113C36.0697 7.87113 32.0516 11.8943 32.0516 16.8404C32.0516 17.9251 32.2537 19.0098 32.6499 20.046C33.9677 23.4944 37.3309 25.8096 41.0095 25.8096C44.688 25.8096 48.0513 23.4944 49.3691 20.046C49.7652 19.0017 49.9673 17.9251 49.9673 16.8404C49.9673 11.8943 45.9492 7.87113 41.0095 7.87113Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M80.4306 75.0917H1.85514C1.20028 75.0917 0.666687 74.5574 0.666687 73.9018V27.1532C0.666687 26.789 0.828382 26.449 1.11135 26.2223L19.4233 11.5299C19.9326 11.1171 20.6845 11.198 21.0887 11.7161C21.501 12.2261 21.4121 12.9789 20.9028 13.3837L3.0436 27.7199V72.7118H79.2422V27.728L61.0354 13.1246C60.526 12.7118 60.4371 11.9671 60.8494 11.4571C61.2617 10.9471 62.0055 10.858 62.5149 11.2709L81.1663 26.2304C81.4493 26.4571 81.611 26.7971 81.611 27.1613V73.9099C81.611 74.5655 81.0774 75.0998 80.4225 75.0998L80.4306 75.0917Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M80.4306 75.0919C80.2204 75.0919 80.0183 75.0352 79.8242 74.9219L1.24877 28.1734C0.682841 27.8415 0.496892 27.1129 0.836451 26.5463C1.16792 25.9796 1.90364 25.7934 2.46148 26.1334L81.0369 72.8819C81.6029 73.2138 81.7888 73.9424 81.4493 74.509C81.231 74.8814 80.8348 75.0919 80.4306 75.0919Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M1.85513 75.0919C1.45089 75.0919 1.05474 74.8814 0.836451 74.509C0.496892 73.9424 0.682841 73.2138 1.24877 72.8819L79.8242 26.1334C80.3902 25.7934 81.1178 25.9796 81.4493 26.5463C81.7807 27.1129 81.6029 27.8415 81.0369 28.1734L2.46148 74.9219C2.27553 75.0352 2.06533 75.0919 1.85513 75.0919Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M61.7791 39.3363C61.1243 39.3363 60.5907 38.802 60.5907 38.1463V2.47172H21.3555V38.0492C21.3555 38.7049 20.8219 39.2391 20.167 39.2391C19.5122 39.2391 18.9786 38.7049 18.9786 38.0492V1.28176C18.9786 0.626066 19.5122 0.0917969 20.167 0.0917969H61.7791C62.434 0.0917969 62.9676 0.626066 62.9676 1.28176V38.1463C62.9676 38.802 62.434 39.3363 61.7791 39.3363Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M54.9718 32.6739H27.7586C27.1037 32.6739 26.5701 32.1396 26.5701 31.4839C26.5701 30.8282 27.1037 30.2939 27.7586 30.2939H54.9718C55.6266 30.2939 56.1602 30.8282 56.1602 31.4839C56.1602 32.1396 55.6266 32.6739 54.9718 32.6739Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M54.7455 38.1138H27.5323C26.8774 38.1138 26.3438 37.5795 26.3438 36.9238C26.3438 36.2682 26.8774 35.7339 27.5323 35.7339H54.7455C55.4003 35.7339 55.9339 36.2682 55.9339 36.9238C55.9339 37.5795 55.4003 38.1138 54.7455 38.1138Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.7047 22.3207H40.6052C39.0934 22.3207 37.8645 21.0902 37.8645 19.5765C37.8645 18.9208 38.3981 18.3865 39.053 18.3865C39.7078 18.3865 40.2414 18.9208 40.2414 19.5765C40.2414 19.7789 40.4031 19.9408 40.6052 19.9408H41.7047C41.9069 19.9408 42.0686 19.7789 42.0686 19.5765V18.4756C42.0686 18.2732 41.9069 18.1113 41.7047 18.1113H40.6052C39.0934 18.1113 37.8645 16.8809 37.8645 15.3671V14.2662C37.8645 12.7524 39.0934 11.522 40.6052 11.522H41.7047C43.2166 11.522 44.4455 12.7524 44.4455 14.2662C44.4455 14.9219 43.9119 15.4804 43.257 15.4804C42.6022 15.4804 42.0686 14.9704 42.0686 14.3147C42.0686 14.0638 41.9069 13.9019 41.7047 13.9019H40.6052C40.4031 13.9019 40.2414 14.0638 40.2414 14.2662V15.3671C40.2414 15.5695 40.4031 15.7314 40.6052 15.7314H41.7047C43.2166 15.7314 44.4455 16.9618 44.4455 18.4675V19.5684C44.4455 21.0821 43.2166 22.3126 41.7047 22.3126V22.3207Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.155 13.7479C40.5001 13.7479 39.9666 13.2136 39.9666 12.5579V10.8985C39.9666 10.2428 40.5001 9.7085 41.155 9.7085C41.8099 9.7085 42.3435 10.2428 42.3435 10.8985V12.5579C42.3435 13.2136 41.8099 13.7479 41.155 13.7479Z"
                    fill="#1C7ABF"
                  />
                  <path
                    d="M41.155 23.9803C40.5001 23.9803 39.9666 23.446 39.9666 22.7904V21.1309C39.9666 20.4752 40.5001 19.9409 41.155 19.9409C41.8099 19.9409 42.3435 20.4752 42.3435 21.1309V22.7904C42.3435 23.446 41.8099 23.9803 41.155 23.9803Z"
                    fill="#1C7ABF"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_701_4159">
                    <rect
                      width="80.9524"
                      height="75"
                      fill="white"
                      transform="translate(0.666687 0.0917969)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Send receipts via email
            </p>
          </article>
          <article
            className="w-[300px] h-[189px] p-6 inline-flex flex-col items-start justify-self-center gap-4 rounded-[25px] border-[2px] border-[#4452A3]"
            style={{ boxShadow: '0px 3px 4px 0px rgba(68, 82, 163, 0.50)' }}
          >
            <figure className="w-[73.773px] h-[75px] relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="74"
                height="76"
                viewBox="0 0 74 76"
                fill="none"
              >
                <path
                  d="M10.3571 75.0918C10.0228 75.0918 9.69579 75.0772 9.36872 75.0481C4.02665 74.6193 0 70.84 0 66.2684C0 65.6651 0.486965 65.1781 1.09022 65.1781C1.69348 65.1781 2.18044 65.6651 2.18044 66.2684C2.18044 69.6989 5.34944 72.5406 9.54315 72.875C9.81207 72.8968 10.0809 72.9114 10.3571 72.9114C14.8706 72.9114 18.5337 69.9315 18.5337 66.2684V62.7723C18.5337 62.1691 19.0207 61.6821 19.624 61.6821C20.2272 61.6821 20.7142 62.1691 20.7142 62.7723V66.2684C20.7142 71.138 16.0698 75.0918 10.3571 75.0918Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M54.1329 75.0918H10.3642C9.76098 75.0918 9.27402 74.6048 9.27402 74.0016C9.27402 73.3983 9.76098 72.9114 10.3642 72.9114C14.8777 72.9114 18.5409 69.9315 18.5409 66.2684V62.7723C18.5409 62.1691 19.0279 61.6821 19.6311 61.6821H63.3998C64.003 61.6821 64.49 62.1691 64.49 62.7723V66.2684C64.49 71.138 59.8457 75.0918 54.1329 75.0918ZM17.1746 72.9114H54.1329C58.6464 72.9114 62.3096 69.9315 62.3096 66.2684V63.8626H20.7213V66.2684C20.7213 68.914 19.3477 71.2906 17.1746 72.9114Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M1.09022 67.4164C0.486965 67.4164 0 66.9295 0 66.3262V1.18202C0 0.578762 0.486965 0.0917969 1.09022 0.0917969H56.2773C56.8805 0.0917969 57.3675 0.578762 57.3675 1.18202V62.7722C57.3675 63.3754 56.8805 63.8624 56.2773 63.8624C55.674 63.8624 55.1871 63.3754 55.1871 62.7722V2.27224H2.18044V66.3262C2.18044 66.9295 1.69348 67.4164 1.09022 67.4164Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M72.6811 66.4282C72.4049 66.4282 72.1214 66.3192 71.9107 66.1084L33.6949 27.8925C33.2661 27.4637 33.2661 26.7732 33.6949 26.3516C34.1238 25.9301 34.8141 25.9228 35.2357 26.3516L73.4516 64.5675C73.8804 64.9963 73.8804 65.6869 73.4516 66.1084C73.2408 66.3192 72.9573 66.4282 72.6811 66.4282Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M32.4957 32.544C32.1468 32.544 31.8125 32.3769 31.609 32.0862L29.036 28.4449L24.5808 28.5029C24.1883 28.5319 23.7812 28.2777 23.5922 27.907C23.4032 27.5363 23.4397 27.0929 23.6868 26.7586L26.3541 23.1827L24.9223 18.9599C24.7915 18.5674 24.8933 18.1313 25.1841 17.8406C25.4748 17.5499 25.9107 17.4482 26.3032 17.579L30.526 19.0108L34.1021 16.3433C34.4364 16.0962 34.8797 16.0599 35.2503 16.2489C35.621 16.4379 35.8536 16.823 35.8464 17.2373L35.7882 21.6927L39.4295 24.2657C39.7711 24.5055 39.9384 24.9198 39.8802 25.3268C39.8148 25.7338 39.5241 26.0754 39.1243 26.1989L34.8651 27.5218L33.5424 31.7808C33.4189 32.1733 33.0844 32.4713 32.6701 32.5367C32.612 32.544 32.5538 32.5513 32.4957 32.5513V32.544ZM29.5884 26.2571C29.9446 26.2571 30.2718 26.4315 30.4753 26.7149L32.1034 29.0191L32.9391 26.3224C33.0481 25.9808 33.3171 25.7119 33.6587 25.6029L36.3551 24.7671L34.0512 23.1391C33.7604 22.9283 33.5859 22.594 33.5932 22.2378L33.6296 19.4177L31.3693 21.104C31.0785 21.3147 30.7078 21.3801 30.3662 21.2638L27.6915 20.3553L28.6001 23.0301C28.7164 23.3717 28.6582 23.7423 28.4402 24.033L26.7539 26.2935L29.5739 26.2571H29.5884Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M23.6577 17.4048C23.3816 17.4048 23.0981 17.2956 22.8873 17.0848L19.2241 13.4217C18.7953 12.9928 18.7953 12.3025 19.2241 11.8809C19.6529 11.4594 20.3435 11.4521 20.765 11.8809L24.4282 15.5441C24.857 15.9729 24.857 16.6633 24.4282 17.0848C24.2174 17.2956 23.9339 17.4048 23.6577 17.4048Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M20.6992 24.5486H15.5171C14.9138 24.5486 14.4269 24.0616 14.4269 23.4584C14.4269 22.8551 14.9138 22.3682 15.5171 22.3682H20.6992C21.3024 22.3682 21.7894 22.8551 21.7894 23.4584C21.7894 24.0616 21.3024 24.5486 20.6992 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M46.0872 24.5486H40.9049C40.3017 24.5486 39.8147 24.0616 39.8147 23.4584C39.8147 22.8551 40.3017 22.3682 40.9049 22.3682H46.0872C46.6904 22.3682 47.1774 22.8551 47.1774 23.4584C47.1774 24.0616 46.6904 24.5486 46.0872 24.5486Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M19.9946 35.3572C19.7184 35.3572 19.4349 35.2482 19.2241 35.0374C18.7953 34.6086 18.7953 33.9181 19.2241 33.4965L22.8873 29.8333C23.3161 29.4045 24.0067 29.4045 24.4282 29.8333C24.857 30.2622 24.857 30.9527 24.4282 31.3743L20.765 35.0374C20.5543 35.2482 20.2708 35.3572 19.9946 35.3572Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M37.9469 17.4045C37.6707 17.4045 37.3873 17.2954 37.1766 17.0846C36.7477 16.6558 36.7477 15.9654 37.1766 15.5439L40.8396 11.8807C41.2684 11.4519 41.9589 11.4519 42.3805 11.8807C42.802 12.3095 42.8093 12.9999 42.3805 13.4214L38.7173 17.0846C38.5065 17.2954 38.223 17.4045 37.9469 17.4045Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 39.8337C30.199 39.8337 29.712 39.3468 29.712 38.7435V33.5614C29.712 32.9582 30.199 32.4712 30.8022 32.4712C31.4055 32.4712 31.8924 32.9582 31.8924 33.5614V38.7435C31.8924 39.3468 31.4055 39.8337 30.8022 39.8337Z"
                  fill="#1C7ABF"
                />
                <path
                  d="M30.8022 14.4467C30.199 14.4467 29.712 13.9597 29.712 13.3565V8.1742C29.712 7.57095 30.199 7.08398 30.8022 7.08398C31.4055 7.08398 31.8924 7.57095 31.8924 8.1742V13.3565C31.8924 13.9597 31.4055 14.4467 30.8022 14.4467Z"
                  fill="#1C7ABF"
                />
              </svg>
            </figure>
            <p className="text-[#23242A] text-xl font-normal font-customFont">
              Automatic tax calculation
            </p>
          </article>
        </section>
        <section className="flex flex-col items-start gap-[42px] self-stretch">
          <h1 className="flex flex-col justify-center items-start self-stretch text-[#23242A] text-[35px] font-semibold font-customFont">
            Boost your bottom line with seamless payments.
          </h1>
          <p className="text-black text-[25px] font-normal font-customFont">
            If you’re accepting Business to Business (B2B) transactions, you can qualify for the{' '}
            <span className="font-semibold text-[#C0F]">
              <i>lowest possible rates</i>{' '}
            </span>
            on card not present (CNP) transactions.
          </p>
          <p className="text-black text-[25px] font-normal font-customFont">
            On most gateways, this would require manually submitting between 5 and 19 additional
            pieces of data with each transaction.
          </p>
        </section>
        <section
          className="flex p-[60px] flex-col gap-[50px] self-stretch rounded-[10px] border-solid border-[2px] border-[#4452A3]"
          style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
        >
          <p className="text-center text-black text-[35px] font-normal font-customFont">
            iQ Pro+{' '}
            <span className="font-semibold text-[#C0F]">
              <i>automatically</i>{' '}
            </span>
            captures and submits this information with each transaction, saving you valuable time
            and money.
          </p>
        </section>
        <section className="flex flex-col items-start gap-[60px] self-stretch">
          <h3 className="text-black text-[35px] font-semibold font-customFont">
            Always have a backup plan.
          </h3>
          <p className="text-black text-[25px] font-normal font-customFont">
            The iQ Pro+ terminal turns any device with internet access into a trustworthy payment
            option if you need it.
          </p>
        </section>
        <section className="flex w-full max-md:w-auto px-[122px] py-[100px] flex-col items-start gap-[50px] bg-[url('./img/softwarePartners.png')] bg-cover bg-bottom md:rounded-3xl">
          <h1 className="flex flex-col items-start justify-center text-white text-[55px] font-medium font-customFont">
            How does the Terminal work?
          </h1>
          <h2 className="text-white text-[35px] font-normal font-customFont">
            The iQ Pro+ Terminal is a fully integrated solution that gives you the power to process
            transactions online.
          </h2>
          <div className="flex flex-col justify-center items-start gap-[50px] self-stretch">
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Manually enter new payment information, swipe with a compatible device or charge a
                  customer's saved payment method.
                </p>
              </div>
            </article>
            <article className="flex items-center gap-[45px] self-stretch">
              <div className="flex items-start gap-[45px] self-stretch">
                <div className="w-[30px] h-[30px] relative">
                  <div className="w-[30px] h-[30px] left-0 top-0 absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_686_3709)">
                        <mask
                          id="mask0_686_3709"
                          style={{ maskType: 'luminance' }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="30"
                          height="31"
                        >
                          <path d="M30 0.0917969H0V30.0918H30V0.0917969Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_686_3709)">
                          <path
                            d="M15 0.0917969C15.5093 8.15574 21.936 14.5826 30 15.0918C21.936 15.601 15.5093 22.0278 15 30.0918C14.4908 22.0278 8.06394 15.601 0 15.0918C8.06394 14.5826 14.4908 8.15574 15 0.0917969Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_686_3709">
                          <rect
                            width="30"
                            height="30"
                            fill="white"
                            transform="translate(0 0.0917969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <p className="text-white text-[25px] font-light font-customFont">
                  Add custom fields to personalize the payment experience and take efficiency to the
                  next level.
                </p>
              </div>
            </article>
          </div>
        </section>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1158px]:grid">
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              Utilize a compatible <span className="font-bold text-[#C0F]">Physical Terminal</span>{' '}
              to accept card present transactions.
            </h2>
            <Link
              to="/features/physicalTerminals"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452A3] learn1"
            >
              <p className="text-xl font-medium text-white font-customFont">Learn More</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
          </article>
          <figure className="h-[522.737px] w-[514px] flex-shrink-0">
            <img
              src={physicalTerminal}
              alt="Physical terminal with a card and how two terminals show up on iQPro+"
            />
          </figure>
        </section>
        <section className="justify-start w-full items-center gap-[120px] inline-flex max-[1158px]:grid">
          <figure className="h-[522.737px] w-[514px] max-[1158px]:hidden flex-shrink-0">
            <img src={MobileTerminal} alt="Mobile terminal screenshot" />
          </figure>
          <article className="inline-flex flex-col items-start justify-center gap-8">
            <h2 className="self-stretch text-black text-[35px] font-normal font-customFont">
              Access iQ Pro+ anywhere with the{' '}
              <span className="font-bold text-[#C0F]">Mobile App</span>.
            </h2>
            <Link
              to="/features/mobileApp"
              className="flex px-4 py-2 justify-center items-center gap-4 rounded-[20px] bg-[#4452A3] learn2"
            >
              <p className="text-xl font-medium text-white font-customFont">Learn More</p>
              <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
                <svg
                  className="absolute arrowNav top-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                    fill="white"
                  />
                </svg>
              </div>
            </Link>
            <figure className="h-[522.737px] w-[514px] min-[1158px]:hidden flex-shrink-0">
              <img src={MobileTerminal} alt="Mobile terminal screenshot" />
            </figure>
          </article>
        </section>
        <section className="h-[157px] max-md:h-auto flex-col justify-start items-center gap-[25px] inline-flex">
          <h1 className="self-stretch text-center text-black text-[45px] font-medium font-customFont">
            Ready to get started?
          </h1>
          <Link
            to="/bookDemo"
            className="flex items-start gap-4 px-4 py-2 rounded-[20px] bg-[#4452A3] bookDemo"
          >
            <p className="text-xl font-medium text-center text-white font-customFont">
              Book a demo today!
            </p>
            <div className="relative inline-flex flex-col items-start justify-start w-8 h-8">
              <svg
                className="absolute arrowNav top-2"
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M17 1.57217V13.6565C17 13.8414 16.9265 14.0188 16.7958 14.1495C16.6651 14.2802 16.4877 14.3537 16.3028 14.3537C16.1179 14.3537 15.9406 14.2802 15.8098 14.1495C15.6791 14.0188 15.6057 13.8414 15.6057 13.6565V3.25469L1.92245 16.9379C1.79029 17.061 1.61549 17.1281 1.43488 17.1249C1.25426 17.1217 1.08193 17.0485 0.954197 16.9208C0.826463 16.7931 0.753295 16.6207 0.750109 16.4401C0.746922 16.2595 0.813965 16.0847 0.937114 15.9525L14.6203 2.26935H4.21848C4.03358 2.26935 3.85625 2.1959 3.7255 2.06515C3.59476 1.9344 3.5213 1.75708 3.5213 1.57217C3.5213 1.38727 3.59476 1.20994 3.7255 1.0792C3.85625 0.948452 4.03358 0.875 4.21848 0.875H16.3028C16.4877 0.875 16.6651 0.948452 16.7958 1.0792C16.9265 1.20994 17 1.38727 17 1.57217Z"
                  fill="white"
                />
              </svg>
            </div>
          </Link>
        </section>
      </main>
      <Footer />
    </>
  );
}
